.timePicker {
  width: 100%;
}
.timePicker .MuiInputBase-root {
  height: 38px !important;
}

.notificationContainer {
  display: flex;
  justify-content: space-between;
}
.notificationContainer .notiftBox {
  width: 33%;
}
.notificationContainer .noteremider {
  width: 33%;
}

.before-meet {
  font-size: 12px;
  color: #2b564c;
  margin-top: 10px;
  width: 28%;
}
.documentrow-titleDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.docfileContainer {
  padding-left: 40px;
  margin-top: 20px;
}
.docFileCard.docCardBg {
  margin-bottom: 20px;
}
.docFileCard {
  min-height: 60px;
  padding: 10px 20px;
  border-radius: 10px;
}
.docIcons svg {
  height: 35px !important;
  width: 35px !important;
}
.docCardBg {
  background: rgba(234, 234, 234, 0.28) !important;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  position: relative;
}
.p-2 {
  padding: 0.5rem !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.styles_chip-input__3aumB {
  box-shadow: 0 0 0 0.2rem transparent;
  border: 1px solid transparent;
  transition: border 0.15s linear, box-shadow 0.15s linear;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.border-0 {
  border: 0 !important;
}
.chipInput input {
  width: 80% !important;
}
.form-control {
  min-width: 100px;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.avatarcontainer {
  display: flex;
  align-items: center;
}
.avatargroup {
  display: inline-flex !important;
  margin-left: 10px;
}
.avatar {
  width: 22px !important;
  height: 22px !important;
}
.avatar-lg {
  width: 32px !important;
  height: 32px !important;
}
