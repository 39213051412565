@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.meeting_mints_root {
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.validate_text {
  padding-left: 12px;
}

.root_heading {
  display: flex;
  justify-content: space-between;
}
.root_decision {
  margin-top: 20px;
}
.root_heading svg {
  cursor: pointer;
}
.titleDec {
  border-right: 1px solid #2b564c;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  padding-right: 10px;
  padding: 8px;
}

.root_decision ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.root_decision ul li:nth-child(even) {
  background: #fcfbf9;
}
.root_decision ul li {
  list-style: none;
  padding: 0px 0;
  font-size: 16px;
  border: 1px solid #e9e9e9;
  font-weight: bold;
  display: flex;
}

.Meeting_decisionRoot_container {
  border-right: 0.5px solid #2b564c;
  padding: 10px 30px;
  width: 15%;
  display: flex;
}
.Meeting_decisionRoot_label {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b564c;
  font-weight: 400;
}
.Meeting_decisionRoot_Title {
  width: 85%;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Add_New_DecisionBtn {
  text-align: center;
  cursor: pointer;
}
.Add_New_DecisionBtn span {
  color: #2b564c;
  font-size: 12px;
  vertical-align: middle;
  justify-content: center;
  display: flex;
}
.Add_New_DecisionBtn span svg {
  height: 10px;
  width: 10px;
  margin-top: 4px;
  margin-right: 10px;
}
.Add_DecisionBtn {
  padding: 20px 20px;
  background: #fcfbf9;
}
.decisionIcon {
  cursor: pointer !important;
}

.CommonPd_decision {
  padding: 20px 20px;
  margin-bottom: 20px;
}
