@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.AddEmpGenInfo p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #484848;
  word-break: break-word;
  margin-top: 5px;
}
.allTaskPages .AddEmpGenInfo p {
  word-break: initial !important;
}
.allTaskPages .css-1jzi3sl-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px;
  margin-top: 10px;
}
.DueDate input {
  width: -webkit-fill-available;
  font-size: 12px;
  color: #484848;
}
.onclickAdd {
  position: absolute;
  right: -8%;
}
.arabic .onclickAdd {
  position: absolute;
  right: 100%;
  margin-right: 10px;
}
.ProjectTabDoc {
  padding-top: 30px;
  margin: 0 auto;
  display: flex;
}
.uploadDocBtn {
  display: flex;
  width: 100%;
  justify-content: end;
}
.uploadDocBtn label {
  min-width: 150px;
  width: 100%;
  padding: 5px 5px;
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
  vertical-align: middle;
  justify-content: center;
}
.doucment .docClose {
  border: 1px solid gray;
  border-radius: 6px;
  width: auto;
  height: 40px;
  position: relative;
  padding: 0 0 0 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
span.pdfIconsvg svg {
  width: 40px;
  height: 40px;
}

.docClose .closeIconRed svg {
  bottom: 40px;
  height: 20px;
  color: red;
  border-radius: 50%;
  background: #fff;
}
span.pdfIconsvg {
  position: absolute;
  left: -3px;
  top: 0;
  width: 40px;
  height: 40px;
}
span.pdfIconsvg svg {
  width: 100%;
  height: 100%;
}
.docClose .closeIconRed svg {
  bottom: 40px;
  height: 20px;
  color: red;
}
.docClose .closeIconRed {
  position: absolute;
  right: -5px;
  top: -8px;
}

.empField {
  position: relative;
  padding-right: 30px;
}
.AddEmpGenInfo input.custonInput,
.AddEmpGenInfo .GenDropdown {
  /* width: 95.8%; */
  color: #484848;
  font-size: 12px;
  width: -webkit-fill-available;
}
.Responsibiltystack {
  padding: 10px 10px;
  background: #ffffff;
  border: 1.68px solid #d5dbe1;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.12),
    0px 1.68px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6.71px;
  width: 98.4%;
}

.GernalForm .Text-Right {
  padding-left: 12.2%;
}
.GernalForm svg {
  margin-right: 10px;
}
.Text-Right {
  margin-left: 9% !important;
}
.empfileview {
  font-size: 15px;
  align-items: flex-start;
}
.empField {
  position: relative;
  padding-right: 30px;
}
.flex-center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.docClose .closeIconRed svg:hover {
  border-radius: 50%;
  background: #fff;
}
.closeIconRed:hover {
  box-shadow: inset 100px 0 0 0 #ffffff;
  color: white;
  border-radius: 50%;
}

.attachment {
  border: 1px solid #d5dbe1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.addressNameContainer {
  color: #fff;
  border-radius: 50px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  background: #b7a160;
  display: inline-flex;
  align-items: center;
  min-width: 64px;
  padding: 6px 16px;
  height: 30px;
  justify-content: space-between;
}
