#scrollableDiv {
  height: 700px;
  overflow: auto;
}
.searchinfo {
  border-bottom: 1px #999999 solid;
  padding: 10px 0;
  margin: 0 20px;
}
.searchtitle {
  color: #b7a160;
}
.searchdescription {
  color: #2b564c;
}
