.tss-1akey0g-MUIDataTableHeadCell-data {
  color: #b7a160;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader,
.tss-ylo811-MUIDataTableSelectCell-headerCell {
  background-color: #fffefd !important;
}

.tss-1dci9uv-MUIDataTableSelectCell-checkboxRoot {
  padding: 20px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #2b564c;
}
.groupMeeting {
  gap: 5px;
  display: flex;
}
.groupMeeting svg {
  height: 35px !important;
  width: 35px !important;
  margin-right: 10px;
}
.meeting {
  display: inline-block;
  padding: 5px 20px 5px 10px;
  background: #ebf3f4c2;
  border-radius: 3px;
}
.txtMeetingMain {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 37px; */
}
.txtMeetingSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
}
.mainContainer .css-1mjel2e {
  padding-top: 0px !important;
}
