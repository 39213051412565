@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.dashboard-meetingDetails {
  display: flex;
  align-items: center;
  background: #f6f8fa;
  min-height: 100%;
  font-size: 14px;
  color: #484848;
  padding: 5px 10px;
}
.dashboard-meetingDetails > p {
  margin: 0 !important;
}

.atStyle {
  margin-left: 10px;
  color: rgb(43, 86, 76);
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.flex-space-between span {
  font-weight: 500;
}
