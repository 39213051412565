@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.box1 {
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
  border-radius: 10px;
  padding: 15px 10px !important;
  margin-bottom: 20px;
  border-width: 0px 0.5px 0.5px 0.5px;
  border: 1px solid #dddddd;
}
.box.box1
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-6.css-1osj8n2-MuiGrid-root {
  padding-top: 20px;
}

.detailsBox svg {
  margin-right: 10px;
}
.detailsBox {
  display: flex;
}
.pdfimg svg {
  width: 50px !important;
  height: 50px !important;
}
.pdfimgA svg {
  height: 50px !important;
  width: 50px !important;
}
.arrowINbox.detailsBox {
  display: flex;
  justify-content: space-between;
}
.MeetingDetailsPage .MainRouting p {
  margin-bottom: 10px;
}

.mainConinner
  span.MuiButton-startIcon.MuiButton-iconSizeMedium.css-1d6wzja-MuiButton-startIcon {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.arabic .buttonFill.request {
  margin-right: 10px !important;
}
.btnRyt {
  margin-right: 10px !important;
}
.ckEditor {
  color: #2b564c !important;
  border: 1px solid #2b564c !important;
  border-radius: 15px !important;
  width: 98%;
}

.ck-content {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ck.ck-editor,
.ck-sticky-panel,
.ck-toolbar {
  color: #2b564c !important;
}
.ModalBG .ck.ck-toolbar {
  border: none !important;
}
.ck-editor__top {
  background-color: rgba(146, 189, 172, 0.13) !important;
  padding-top: 10px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  opacity: 1;
  background-color: #fff !important;
  background: red;
  box-shadow: 2px 2px #000;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: 0;
  opacity: 1 !important;
  z-index: 99999 !important;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: #fff !important;
  position: absolute;

  min-height: 200px;
}

.ck-toolbar_grouping {
  background-color: rgba(146, 189, 172, 0) !important;
  mix-blend-mode: darken;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconShare {
  color: #2b564c !important;
}

.votingStepper .MuiStepIcon-text {
  display: none !important;
}

.votingStepper.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #02b935;
}

.votingStepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #02b935 !important;
  background: #fff !important;
  border-radius: 100%;
}

.votingStepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #ff9900;
}

.votingStepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ff9900;
}
.FilterReassign {
  display: flex;
  justify-content: space-between;
}
