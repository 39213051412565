.input-label {
  width: 15%;
}
.input-label p {
  font-size: 16px;
  font-weight: 800;
  color: #000;
  margin: 0;
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-container-hint {
  margin-inline-start: 25%;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
}
.input-container input {
  @extend %form-input;
  padding: 10px 20px;
}
.input-container input::placeholder {
  color: #999;
}
.input-container select {
  @extend %form-input;

  padding-inline: 20px;
}

.input-text-large textarea {
  height: 150px;
  border-radius: 10px;
  padding: 10px 20px;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  resize: vertical;
  @extend %form-input;
  padding: 10px 20px;
}

.text-container {
  margin-bottom: 20px;
  width: 100%;
}
.form-btns {
  display: flex;
  width: 400px;

  margin: auto;
}

button.form-btn,
div.form-btn,
span.form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a5f9e;
  border-radius: 5rem;
  color: white;
  cursor: pointer;
  padding: 0.5rem 5rem;
  margin: auto;
  font-size: 2.2rem;

  transition: all 0.2s;
  /* &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.99);
  } */
}

.input-error {
  @apply mx-auto mb-[2rem] w-[90%] rounded-[.7rem] bg-red-200 p-2 px-[1rem] font-bold text-red-800;
}
