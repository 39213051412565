@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.headerForm p {
  position: relative;
  text-align: center;
}

.headtxt {
  text-overflow: ellipsis;
  margin-top: 5px;
  max-width: 280px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center !important;
  margin: 0 auto;
}
.form_previewTemplate .Assred {
  position: absolute;
  top: 20px;
}
.form_previewTemplate.addSignzz {
  min-height: 200px;
  align-items: center;
  vertical-align: middle;
}

.Assred {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -30%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 119%);
}
.CrossBtn label span {
  font-size: 14px;
}
span.sideHeaderIcon svg {
  margin-right: 0px !important;
}
.innerDoc .CrossBtn > span {
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  font-size: 12px;
}
span.sideHeaderIcon {
  position: absolute;
  right: 0px;
  top: 0px;
}
.centerFlex input {
  padding: 5px;
  height: 15.1px;
}
.centerFlex svg {
  height: 20px;
}
.ModalBG .centerFlex svg {
  height: 20px;
  color: #2b564c;
}
.customButtonBlank {
  width: 110px;
  height: 30px;
  color: #2b564c !important;
  border: 1px solid #2b564c !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 9px !important;
  line-height: 33px;
}

.css-1qvr50w-MuiTypography-root {
  margin: 0;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 1.334;
  letter-spacing: 0em;
  display: block;
}

.progressBar svg {
  width: 50px !important;
  height: 50px !important;
}

.cardContant {
  margin-left: 10px;
  display: grid;
}

.arabic .cardContant {
  margin-right: 10px !important;
  margin-left: 0px;
}

.buttonGroup1 {
  text-align: right;
  gap: 5px;
  display: flex;
  justify-content: right;
}

.arabic .buttonGroup1 {
  text-align: left;
  gap: 5px;
  display: flex;
  justify-content: left;
}
.perviewBox svg {
  width: 50px !important;
  height: 50px !important;
}

.title {
  font-size: 20px !important;
}

.box {
  /* display: flex; */
  box-shadow: 2px !important;
  border-radius: 10px;
  margin-top: 16px;
  padding: 16px;
  border: 2px solid #f1f1f1;
}

.logoCreateDoc img {
  background-repeat: no-repeat;
  padding: 15px 15px;
  width: 460px !important;
}

.logoCreateDoc {
  text-align: center;
}

.mainDoc {
  margin-bottom: 2px;
  margin-top: 2px;
  margin-right: 12px;
  border-radius: 10px;
  background-color: #ffff;
}

.innerDoc {
  margin-bottom: 15px;
  margin-top: 2px;
  border: 1px solid #2b564c;
  border-radius: 10px;
}

.fillSlide {
  display: flex;
  border-radius: 12px;
  background: rgba(43, 86, 76, 1);
}

.headerForm tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

.headerForm tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.headerForm .css-34nofg-MuiTableRow-root:last-child td,
.css-34nofg-MuiTableRow-root:last-child th {
  border: 0px solid;
  padding: 0px 0px 0px 5px;
  text-align: center;
}

.footerForm {
  height: auto;
  border-right: 1px solid rgba(218, 192, 115, 1);
  text-align: center;
  min-height: 50px;
}

.headerForm p,
.footerForm p {
  color: #fff;
}

.slideText {
  font-size: 15px !important;
  color: rgba(43, 86, 76, 1);
}

.borderLeftRight {
  border-radius: 8px;
}

.borderLeft {
  border-radius: 8px 0 0 8px;
}

.borderRight {
  border-radius: 0 8px 8px 0;
}

.outerHeader > .footerForm:nth-child(odd) {
  border-radius: 8px 0 0 8px;
}

.outerHeader > .footerForm:nth-child(even) {
  border-radius: 0 8px 8px 0;
}

.menuTableRel {
  position: relative;
}

.form_previewTemplate {
  position: relative;
}

.form_previewTemplate .headerForm:after {
  content: " ";
  position: absolute;
  border-left: 0.5px #dac073 solid;
  top: 0%;
  right: 0;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.innerDoc div > .form_previewTemplate:last-child .headerForm:after {
  display: none !important;
}

.headerForm {
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(43, 86, 76, 1);
  top: 57px;
  height: 30px;
  padding: 10px 10px;
}

header ul.menuTableList li a {
  display: flex;
  vertical-align: middle;
  width: 100%;
  justify-content: left;
  text-align: left !important;
  padding: 12px 10px !important;
  display: block;
  cursor: pointer;
}

/**border after end here in template*/
.menuTableList ul {
  position: absolute;
  padding: 0;
  left: 0;
  display: none;
}

.menuTableListli:hover ul ul {
  display: none;
}

.menuTableList li:hover ul {
  display: block;
}

.menuTableList li li:hover ul {
  display: block;
  margin-left: 200px;
  margin-top: -35px;
}

.TableMenu ul.dropdown-menu {
  position: absolute;
  left: -56%;
  bottom: 0%;
  background: #fff;
}

.menuTableList {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 0px 0;
  border-radius: 50px;
}

.TableMenu.Profileclick {
  top: 92%;
}

.menuTableList li {
  border-bottom: 1px solid rgb(43 86 76 / 39%);
  padding: 0 !important;
  text-align: left;
}
header .menuTableList li {
  border-bottom: 1px solid rgb(43 86 76 / 39%);
  padding: 0 !important;
  text-align: left;
}
.Profileclick .Flagnav.menuLanBtn button {
  padding: 0px;
  width: 100%;
}
.TableMenu ul.menuTableList li svg path {
  fill: #2b564c;
}

.menuTableList li:last-child,
.menuTableListP li:last-child {
  border-bottom: none;
}

.menuTableList li:hover {
  background: #2b564c3b;
}

.menuTableList li a {
  color: #000;
  border: 0;
  outline: 0;
  margin: 0 auto;
  padding: 8px 10px;
  display: block;
  cursor: pointer;
}
.sideicon {
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  color: #2b564c;
}

.TableMenu {
  position: absolute;
  top: 75%;
  right: 10px;
  width: 100%;
  max-width: 215px;
  height: auto;
  z-index: 999999;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
  border-radius: 10px;
}

.sideHeaderIcon {
  cursor: pointer;
  float: right;
}

.blankSlide {
  display: flex;
}

.slide {
  border-right: 1px solid rgba(218, 192, 115, 1);
  text-align: center;
  width: 50%;
  height: 50px;
}

.slide p {
  margin-top: 14px;
}

.iconTool {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.iconTool.footerForm h6 {
  font-size: 14px !important;
  font-weight: 500;
}

.blankSlide .iconTool svg:nth-child(4) {
  border: 2px solid orange;
  margin-bottom: 1px;
}

.iconTool svg {
  height: 15px;
  margin-right: 3px;
}

.forms_card {
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.forms_card p {
  margin-right: 12px;
  font-size: 12px !important;
}

.FormDoc-Card.forms_card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormDoc-Card.forms_card.active {
  border: 1px solid black;
  background: #c9c8c8ba;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forms_card svg {
  width: 80px !important;
}

.outerHeader .outerHeadinput {
  width: 100%;
  margin-top: 5px;
}

.CrossBtn {
  display: flex;
  position: relative;
  /* flex-direction: row-reverse; */
  margin: 10px 10px;
  justify-content: center;
}
.closeIcon {
  top: -10px;
  right: -13px;
  cursor: pointer;
  position: absolute;
}

.closeIcon svg {
  height: 15px;
}

.dropdown-menu .rootDiv img {
  width: 25px;
  height: 20px;
  vertical-align: middle;
}
.box input {
  width: auto;
}
.boxRadio {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}

.rootRadio .addRadio {
  cursor: pointer;
  margin-top: 8px;
}
.rootRadio {
  display: flex;
  justify-content: space-between;
}

.CrossBtn select {
  width: 100px;
}
.rootRadio .MuiFormGroup-root.MuiFormGroup-row.css-qfz70r-MuiFormGroup-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
}

.CrossBtn2 table {
  margin-top: 8px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

.CrossBtn2 thead {
  background-color: #1c87c9;
  color: #ffffff;
}

.templateTable {
  border-collapse: collapse;
  width: 100%;
}

.templateTable td,
.templateTable th {
  border: 1px solid #ddd;
  padding: 5px 0px;
}

.templateTable tr:hover {
  background-color: #dfdfdf4d;
}

.templateTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #efefef;
  color: #484848;
  font-size: 12px;
}

.templateTable .footerForm {
  min-height: 0;
}

.templateTable .CrossBtn {
  margin: 0px 10px;
}

.clearTable {
  height: 1px;
  width: 100%;
  display: block;
  clear: both;
}

th[scope="row"] {
  background-color: #d7d9f2;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

.CrossBtn2
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  padding: 0px 0px 0px 7px;
  border-bottom: 1px solid rgb(190, 190, 190);
}

.table .footerForm {
  border-right: none;
}

.CrossBtn.cutombtnImg {
  height: auto;
  width: auto;
}

.CrossBtn.cutombtnImg img {
  height: 100px;
  width: 100%;
}

.signPad {
  border: 1px solid black;
}

.interFile {
  border-radius: 10px;
}
.interFile .folder {
  border-bottom: 2px solid #f4f4f4;
  padding: 12px 0 0 0;
  margin-left: 60px;
}

button.ActionMain {
  padding: 10px 10px;
  width: auto;
  font-size: 10px !important;
  height: 40px;
  min-width: 150px !important;
  text-align: center;
  color: #fff !important;
}
.ActionTopBtn.Flagnav.menuLanBtn button span {
  margin-left: 10px;
  color: #2b564c !important;
}
.ActionTopBtn a.dropdown-toggle {
  color: #2b564c;
  text-decoration: none;
}
.ActionTopBtn.Flagnav.dropdown-menu {
  position: absolute;
  left: 0;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  display: inline;
  top: 56px;
  background: #fff;
  padding: 6px;
  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.2);
}
.ActionTopBtn.dropdown-menu.rootDiv {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.ActionTopBtn.Flagnav ul.dropdown-menu li a {
  text-transform: capitalize;
  padding: 5px 5px;
  font-size: 12px;
}
.ActionTopBtn.dropdown-menu.rootDiv {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.Flagnav.dropdown-menu a {
  width: auto;
  color: #2b564c !important;
}

.ActionTopBtn .Flagnav .dropdown-menu {
  width: 100%;
  max-width: 150px;
}
.ActionTopBtn.Flagnav li {
  list-style: none;
  display: block;
}
.ActionTopBtn .dropdown-menu .rootDiv {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 150px;
}
.ActionTopBtn.Flagnav li {
  border-bottom: 1px solid rgb(43 86 76 / 39%);
  padding: 8px 10px;
  text-align: left;
}
.topCompDocTtile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ActionTopBtn .Flagnav .dropdown-menu {
  position: absolute;
  left: -23px;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  display: inline;
  top: 56px;
  background: #fff;
  padding: 6px;
  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.2);
}

.ActionTopBtn.Flagnav li:last-child {
  border-bottom: none;
}

.avatargroup.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
  width: 20px;
  height: 20px;
}
.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
  font-size: 10px !important;
  border: 2px solid #fff;
  box-sizing: content-box;
  margin-left: -5px;
}
.folderArrow .iconArrow {
  width: 25px !important;
  color: #757575;
}

.avatarcontainer {
  display: flex;
  align-items: center;
  border-radius: 9px;
  padding: 10px 10px;
}
.avatargroup {
  display: inline-flex !important;
  margin-left: 10px;
}
.avatar {
  width: 22px !important;
  height: 22px !important;
}
.avatar-lg {
  width: 32px !important;
  height: 32px !important;
}

.allCommantAvtar .avatarcontainer {
  display: flex;
  align-items: center;
  width: 550px;
  background: rgba(54, 122, 246, 0.16);
  border-radius: 9px;
  padding: 10px 10px;
}
.allCommantAvtar .avatargroup {
  display: inline-flex !important;
  margin-left: 10px;
}
.allCommantAvtar .avatar {
  width: 22px !important;
  height: 22px !important;
}
.allCommantAvtar .avatar-lg {
  width: 32px !important;
  height: 32px !important;
}
.allCommentPg .NameComment {
  color: #2b564c;
  font-size: 14px;
  font-weight: 600;
}

span.HideText {
  white-space: nowrap;
  width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.commentCursor {
  cursor: pointer;
}

.qr svg {
  height: 100px !important;
  width: 100px !important;
}
.commentOuter {
  display: flex;
  justify-content: space-around;
}
.createDocMenu {
  z-index: 999;
  top: 38px !important;
}

.CrossBtn a {
  color: red;
}
