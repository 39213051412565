@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.CalenderWrapper .css-epd502 {
  width: 100% !important;
  overflow-x: hidden;
  width: 320px;
  max-height: 358px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-content: center;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgb(43 86 76 / 12%), 0px -3px 4px rgb(43 86 76 / 12%);
  border-radius: 10px;
  /* border: 1px solid #E8E8E8; */
}
.flex {
  display: flex;
}
.hello {
  display: grid;
}
.addMeetingPg .validate {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 15%;
}
.timeBox1 .validate {
  position: absolute;
  left: 37%;
}
.noteremider .validate {
  position: absolute;
  left: 41%;
}
.flexJustify {
  display: flex;
  align-items: center;
}
.flexJustify small {
  display: block;
  margin-left: 5px;
}
/* //////////////////////////////////// */
.closeDocIcon {
  font-size: 10px;
}
.flexJustify svg {
  cursor: pointer;
  height: 12px;
  margin-left: 10px;
  margin-bottom: 2px;
  width: 12px;
}
.details_box {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background: #f6f9f8;
  margin-bottom: 12px;
}
.details_box button {
  height: 50% !important;
}
.messege_meeting {
  text-align: center;
  padding-bottom: 25px;
  color: #2b564c;
  font-size: 12px;
}
/* .addMeetingPg{
    padding: 0px 30px 30px 30px;
} */
.addMeetingPg .inputBox {
  width: 60%;
}
.addMeetingPg .branchBox {
  display: flex;
  width: 50%;
}

.addMeetingPg .branchBox input {
  height: 30%;
  margin-left: 12px;
}
.FormsuggestForm {
  padding: 5px;
}
.FormsuggestForm .descriptionBox {
  width: 70%;
}
.FormsuggestForm .timeBox {
  width: 17.4%;
}
.addMeetingPg .TimeZone input {
  padding: 5px 10px;
  font-size: 12px;
  color: #484848;
}
.addMeetingPg label {
  /* display: inline-block;  */
  width: 100%;
}

.CalenderWrapper {
  position: relative;
}
.CalenderWrapper .MuiPickersCalendarHeader-labelContainer {
  text-align: center;
  margin: 0 auto;
  color: #2b564c;
  font-size: 16px;
}

.CalenderWrapper .css-169iwlq-MuiCalendarPicker-root {
  background-color: #fff !important;
  padding: 20px 20px;
  width: auto !important;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(43, 86, 76, 0.12),
    0px -3px 4px rgba(43, 86, 76, 0.12);
  border-radius: 15px;
  margin: none !important;
  margin-bottom: 20px;
}
.CalenderWrapper button.PrivatePickersYear-yearButton {
  /* display: flex; */
  justify-content: center;
  vertical-align: middle;
}
.CalenderWrapper
  .MuiPickerStaticWrapper-content.css-xelq0e-MuiPickerStaticWrapper-content {
  padding: 20px 10px;
}
.CalenderWrapper .css-3eghsz-PrivatePickersYear-button.Mui-selected:focus {
  background-color: #2b564c !important;
}
.CalenderWrapper span.MuiDayPicker-weekDayLabel {
  width: 100%;
  display: flex;
}
.CalenderWrapper .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
  width: 100%;
  max-width: 100%;
  margin: 0px;
}

.CalenderWrapper .MuiDayPicker-weekContainer {
  display: flex;
  justify-content: space-around;
}

.CalenderWrapper
  .css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid rgb(43 86 76);
  background-color: #2b564c;
  color: rgb(255 255 255 / 87%);
}
.CalenderWrapper
  button.MuiIconButton-root.MuiIconButton-edgeStart.MuiPickersArrowSwitcher-button {
  position: absolute;
  right: 0px;
  top: 12em;
}
.CalenderWrapper button.MuiIconButton-edgeEnd.MuiPickersArrowSwitcher-button {
  position: absolute;
  left: 0px;
  top: 12em;
}

.CalenderWrapper .css-169iwlq-MuiCalendarPicker-root {
  overflow-y: hidden !important;
}
.CalenderWrapper
  .css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
  display: block;
  position: relative;
  min-height: 240px;
}
.CalenderWrapper .css-1qbc4x0-MuiYearPicker-root {
  overflow-y: hidden !important;
}
.Titlezone {
  text-align: center;
}
.addMeetingPg .notifiyinput .notiftBox {
  width: 26.5%;
}

.CalenderWrapper
  .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition.css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
  overflow-y: hidden !important;
}
.addMeetingPg .notifiyinput input {
  height: 0px;
  margin-left: 10px;
  width: 80%;
  font-size: 12px;
  color: #484848;
}
.decsrtion textarea {
  font-size: 12px;
  color: #484848;
  text-transform: capitalize;
}
.addMeetingPg .authoritiesGroup.css-rswrts-MuiStack-root {
  width: 100%;
}
.addMeetingPg #demo-simple-select input {
  width: 80%;
}
.addMeetingPg .selectBox {
  width: 100%;
  max-width: 100%;
}
.addMeetingPg .FormsuggestForm {
  width: auto;
  display: flex;
  margin: 10px 0px 15px;
}
.addMeetingPg .notifiyinput .selectBox {
  width: 100%;
  max-width: 95%;
}

.CalenderWrapper .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgb(43 86 76 / 12%), 0px -3px 4px rgb(43 86 76 / 12%);
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.Guetss input {
  height: 15.5px;
  padding: 10px !important;
  border: 1px solid #d5dbe1;
}
.meetTitle input {
  width: 65%;
}
.DatePick input {
  width: 100%;
}
.addMeetingPg label.Titlezone {
  flex: 0 0 5%;
}
.addMeetingPg
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: auto;
}
.meetTitle {
  width: 100%;
  max-width: 60%;
}

.decsrtion {
  width: 100%;
}
.Notifiy {
  width: 38.8%;
}
.addMeetingPg form {
  padding: 10px 10px;
}
.Guetss {
  width: 65%;
}

.addMeetingPg .authoritiesGroup.css-rswrts-MuiStack-root {
  width: 100%;
}

.Guetss input {
  height: 15.5px;
  padding: 10px !important;
  border: 1px solid #d5dbe1;
}

.authoritiesGroup.css-rswrts-MuiStack-root
  .css-1q60rmi-MuiAutocomplete-endAdornment {
  position: absolute;
  right: 15px;
  top: calc(50% - 14px);
}
label.uploadFile {
  width: 100%;
  padding: 5px 5px;
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
  vertical-align: middle;
  justify-content: center;
}

.meetTitle input {
  width: 100%;
}
.loctiondv {
  width: 62%;
}
.SaveMeet {
  display: flex;
  justify-content: flex-end;
}
.addMeetingPg label.Titlezone {
  justify-content: center;
  display: flex;
}
.FormsuggestForm label {
  font-size: 14px;
  font-weight: 500;
  color: #2b564c;
}
.SaveMeet {
  display: flex;
  justify-content: flex-end;
}

.FormsuggestForm {
  position: relative;
}
.FormsuggestForm label {
  vertical-align: middle !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
}

.validation-messages {
  font-size: 12px;
  color: red;
}

.meetingtimepicker .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
}
.meetingtimepicker .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
