.promotionStatus {
  margin-left: 20px;
  padding: 2px 10px;
  border-radius: 20px;
  color: white;
  text-transform: uppercase;
}
.highlight {
  margin-left: 5px;
  font-weight: bolder;
}
