.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 9px;
  padding-inline: 15px;
  border-radius: 2px;
  /* margin-bottom: 4rem; */
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
  flex: 1;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 20px;
}
