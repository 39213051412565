.logDetails{
   border-bottom: 1px solid #2b564c;
   padding: 20px 0;
   margin-right: 20px;
}
.logDetails:last-child{
   border: none;
   padding-bottom: 0;
}
.logNotes{
  margin-bottom: 20px;
}
.logNoteList{
   margin-left: 20px;
}
.noteFlex{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.noteAvatar{
    max-width: 600px;
}
.noteFlex1 h3{
  margin-top: 0;
}
.noteFlex1{
  margin-bottom: 10px;
  display: flex;
  justify-content:space-between;
}
.replySection{
  display: flex;
justify-content: space-between;
margin-bottom: 20px;
}
.replyContainer h3{
   font-size: 15px;
}
.changeValue{
font-size: 12px;
margin: 0;
padding: 0 3px;
color: rgb(43 86 76);
font-weight: 600;
}

.replyInput{
   width: 75%;
   margin-left: 50px;
   height: 15px;
}
.replyInput input{
    width: 460px!important;
}
.replyList{
   display: flex;
}
.replyContainer{
 margin-bottom: 20px;
 margin-left: 50px;
 
}
.logNoteList h2{
  font-size: 12px;
  margin: 0;  
}
.textCenter{
    text-align: center;
    margin: 0; 
}
@media only screen and (max-width: 767px)  {
   .flex-wrap{
      flex-wrap: wrap;
   }
}
