.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before,::after{
    border-bottom:none !important;
   
}
.addMeetingPg .flexFil {
    position: relative;
}
.addMeetingPg .flex-set {
    position: absolute;
    bottom: 0%;
    display: inline-block;
    top: 0px;
    left: -110px;
}