@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.arabic .ReqstAsignPage .css-h8hsxn-MuiGrid-root {
  justify-content: left !important;
}

.ReqstAsignPage .fill1 {
  width: unset !important;
}

.ReqstAsignPage .request {
  width: 140px;
  margin-left: 15px !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.ReqstAsignPage .to {
  display: inline-block;
  margin: 0 10px;
  color: #2b564c;
  font-size: 19px;
  font-weight: 600;
}

.ReqstAsignPage .title {
  font-size: 16px !important;
  color: #484848 !important;
}

.ReqstAsignPage .title1 {
  font-size: 16px !important;
  color: #b7a160 !important;
}

.ReqstAsignPage .pending {
  border-radius: 20px !important;
  height: 30px;
  background-color: #ff9900 !important;
  font-size: 14px !important;
  text-transform: capitalize;
}
.ReqstAsignPage .viewDoc {
  border-radius: 20px !important;
  height: 30px;
  margin-right: 5px;
  background-color: #567a74 !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.ReqstAsignPage .done {
  border-radius: 20px !important;
  height: 30px;
  background-color: #02b935 !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.btnContainer {
  justify-content: flex-end;
}
.partnerCard-btnContainer {
  padding-bottom: 20px;
}

.calenderBox,
.commentBox {
  display: flex;
  justify-content: space-between;
}

.BodDecisionPage .commentBox {
  justify-content: start;
}

.BodDecisionPage .commentBox span {
  color: #2b564c;
  font-size: 12px;
}

.BodDecisionPage .commentBox span small {
  color: #b7a160;
  margin-left: 8px;
  font-weight: 500;
}

.BodDecisionPage .commentSection {
  display: flex;
  justify-content: space-between;
}
.BodDecisionPage .commentBox.addComment {
  display: flex;
  justify-content: space-between;
}
.BodDecisionPage .commentBox.addComment input {
  width: 100%;
  margin-right: 10px;
}
.BodDecisionPage
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-12.css-1yl2ad8-MuiGrid-root {
  width: 100%;
}

.arabic .BodDecisionPage .commentBox.addComment input {
  margin-right: 0px;
  margin-left: 10px;
}
.BodDecisionPage .commentBox img {
  height: 30px;
  width: auto;
}
.calenderBox img {
  height: 35px;
  width: auto;
}
.ExpensesProfitPage p.expenseProfitTophead {
  font-size: 14px !important;
  font-weight: 500;
  color: #2b564c;
}
.calenderBox1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainContainer {
  padding: 20px 10px;
}

.cardContact {
  display: flex;
  justify-content: space-between;
}

.btn1 {
  padding-top: 10px;
  justify-content: center;
}

.commentBox {
  margin-top: 20px;
}

.inputComment {
  width: 81%;
}

.cardContact1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ml-10 {
  margin-left: 10px !important;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.min-w-130 {
  min-width: 130px !important;
}
.reqbtnContainer {
  display: flex;
  justify-content: space-between;
}
.reqHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reqaddbtn {
  margin-left: 10px;
}

@media (max-width: 560px) {
  .reqHeader {
    flex-direction: column;
  }
  .reqbtnContainer {
    align-items: flex-end;
  }
  .reqaddbtn {
    margin-left: 0px;
  }
  .ReqstAsignPage .request {
    margin-left: 0px !important;
  }
}

.mt-0 {
  margin-top: 0px;
}
