@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #969595;
}
a,
button {
  cursor: pointer;
}
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.hideShowSearch.css-1iz4iln {
  display: none;
}

.hideShowSearch .css-1idedao-MuiSvgIcon-root {
  color: #2b564c !important;
}
.profileWrapper {
  cursor: pointer;
}
.clcokInOut {
  display: flex;
  align-items: center;
}

.ToggleSet {
  display: flex;
  align-items: center;
  width: 170px;
}

.sidebarfixed {
  position: fixed !important;
  top: 0;
  z-index: 2;
  width: 300px !important;
  background-color: #ffffff;
}
.sidebarpopOver {
  position: absolute !important;
  top: 0;
  z-index: 99;
  width: 200px !important;
  background-color: #ffffff;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.ml-305 {
  margin-left: 305px !important;
}
.mr-305 {
  margin-right: 305px !important;
}

/*lOGIN PAGE style start**/

.wtrapperr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loginOuter {
  margin-top: 10px;
}
.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  left: 7px !important;
  right: inherit;
}
.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  left: 7px !important;
  right: inherit;
}
.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  left: 7px !important;
  right: inherit;
}

.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  left: 7px !important;
  right: inherit;
}

.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root {
  margin-left: 2px !important;
  margin-right: 0px !important;
}

.arabic
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault.css-i4bv87-MuiSvgIcon-root {
  margin-left: 10px !important;
}

.mainLogin h6.lableLogin {
  /* vertical-align: middle; */
  font-size: 14px !important;
  line-height: 0px;
  text-align: left;
  font-weight: 500 !important;
}

.mainLogin .loginFlex {
  display: flex;
  /* align-items: end; */
  margin-bottom: 25px;
}

.innerLogin {
  font-size: 2.5rem;
  margin: 35px auto 0 auto;
  width: 500px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px -4px 4px rgba(100, 100, 100, 0.25),
    0px 4px 4px rgba(100, 100, 100, 0.25);
  border-radius: 20px;
}

.wtrapperr .css-544k9b-MuiGrid-root {
  margin-top: 20px;
}

.forgotCode a {
  font-size: 12px;
  font-weight: 400;
  color: #b7a160;
}

.titleOuter {
  padding: 20px;
}

.loginFlex {
  display: flex;
  align-items: center;
}

.loginTitle {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #2b564c;
  text-transform: uppercase;
}
.forgetPwd p {
  text-transform: capitalize !important;
  padding-left: 0px;
}
.forgotCode {
  text-align: left;
  padding: 0 6px;
  margin-top: 6px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #2b564c;
}

.ToggleSet span.MuiSwitch-track.css-1yjjitx-MuiSwitch-track {
  background-color: #c6c6c6;
  border: 0;
  opacity: 1;
}
.ToggleSet
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #a9c2bb !important;
}
.folderIvcccIcon {
  display: flex;
  vertical-align: middle;
  margin-top: 10px;
}

.ToggleSet .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #2b564c;
}
.ToggleSet .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #a1a1a1;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.folderArrow span {
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
}

.OpenBtn :hover {
  background-color: transparent !important;
}

.headerTop button {
  padding: 5px 5px;
  font-size: 10px !important;
  text-align: center;
  background-color: #2b564c !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.AddIconGreen.css-i4bv87-MuiSvgIcon-root {
  color: red;
  margin-left: 10px;
}
.allTaskPages .AddEmpGenInfo {
  padding-left: 10px;
}

/* clockIN-clcokOut */
span.ClickOn,
span.ClickOff {
  color: #2b564c;
  font-size: 12px;
  font-weight: 500;
}
.arabic .NameComment {
  margin-right: 10px;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

*:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.validateError {
  color: red;
  font-size: 12px;
}

.FormDoc-Card {
  min-height: 150px;
}
.arabic .nav_wrapper_box svg {
  color: #2b564c;
  transform: rotate(90deg) !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-border-radius: 4px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #2b564c;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 4px;
}
.validate {
  font-size: 10px;
  color: red;
  text-transform: capitalize;
}
.modalGroup {
  padding: 10px 10px 10px 10px;
  margin-top: 5px !important;
  display: flex;
  position: relative;
}

.noDataFound {
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b564c;
  font-weight: 500;
}

.TabGreen {
  color: white !important;
  background-color: #2b564c !important;
}
span.notfound {
  font-size: 12px;
  margin: 0 auto;
}
.NotificationBtn {
  display: flex;
  justify-content: flex-end;
}
.ReqstAsignPage .detailsBox h5 {
  word-break: break-all;
}
.ModalBG {
  position: relative;
  border-radius: 10px;
  padding: 20px 20px;
}

.Toastify div {
  font-size: 12px;
}

.perviewBox {
  display: flex;
  justify-content: space-between;
}

.textStringRoot {
  display: flex;
  width: 100%;
}

.hrsTimeWorks p {
  color: #2b564c;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.addMeetingPg .validate {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  justify-content: initial;
  display: flex;
}

.uploadFile.validate {
  justify-content: initial;
  display: flex;
}

.textStringRoot span {
  margin-top: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.mainCon {
  display: flex;
  justify-content: space-between;
}
.TabActive .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  overflow-x: auto !important;
}
.CommonPd {
  margin-bottom: 20px;
}

.authoritiesGroup.multiselect
  .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active,
.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed {
  background: red;
}

.steperBtns.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #02b935;
}

.steperBtns .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #02b935 !important;
  background: #fff !important;
  border-radius: 100%;
}

.MuiStepConnector-horizontal.Mui-active span,
.MuiStepConnector-horizontal.Mui-completed span {
  border-color: #02b935 !important;
}

.MuiStepLabel-iconContainer.Mui-active + .MuiStepConnector-horizontal span {
  border-color: #02b935 !important;
}

.steperBtns .MuiStepLabel-labelContainer span {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: -87px;
  top: 15px;
  width: 200px;
}

.steperBtns .css-1bw0nnu-MuiStep-root {
  width: 0px;
  position: relative;
}

.steperBtns {
  padding: 0 10em;
  min-height: 80px;
  /* border-bottom: 1px solid #9999; */
  /* padding: 20px 10px; */
}

.steperBtns:nth-child(3) {
  border-bottom: none;
}

.voteValdate .gridLeft span {
  padding: 20px 20px;
}

.steperBtns .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #ff9900;
}

.steperBtns .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ff9900;
}

.steperBtns span.MuiStepLabel-label {
  margin-top: 10px;
  white-space: nowrap;
}

.voteValdate .css-1bw0nnu-MuiStep-root {
  padding-left: 0px;
  padding-right: 0px;
}
s .dateMonth input {
  border: none;
  font-size: 14px;
  line-height: 38px;
  color: #2b564c;
}

.sickLeave,
.casual_leave,
.annual_leave {
  color: #fff;
  border-radius: 50px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  background: #b7a160;
  display: inline-flex;
  align-items: center;
  padding: 6px 16px;
  height: 30px;
  margin-right: 10px;
}

.casual_leave {
  background: #2b7d6a;
}

.annual_leave {
  background: #4e4e4e;
}

input.custonInput[type='month']::-webkit-calendar-picker-indicator {
  /* display: none !important; */
  background-image: url('../images/arrow.svg');
  position: absolute;
  right: 10%;
  top: 10px;
}

.root_heading span svg {
  color: red !important;
  height: 16px;
  width: 10px;
}

g#close {
  color: red;
}

html body div .ck-editor {
  border: 1px solid #d3d3d3 !important;
  overflow-y: auto;
  border-radius: 15px;
}
.MainRouting .ck.ck-content p {
  font-size: 12px;
  color: #484848;
  font-size: 12px;
}

.ckEditor.MeetingDecisionPg .ck.ck-toolbar.ck-toolbar_grouping {
  border: none;
}
.TableALLcommon
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-y8ay40-MuiTableCell-root {
  padding: 15px 10px !important;
  word-wrap: break-word;
  min-width: 120px !important;
}

.modalGroup h1.lable {
  font-size: 14px;
  font-weight: 500;
  color: rgb(43 86 76);
}

.ck.ck-editor {
  position: relative;
  min-height: 200px;
}

.ck.ck-editor .ck-dropdown__panel {
  overflow: hidden;
  overflow-y: auto;
  max-height: 120px;
  height: 120px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 120px;
  border: none !important;
}

/**ck editor end**/
.arabic .sideicon {
  margin-right: 10px;
}
.arabic .TableALLcommonTopLaw .tableTopLaw {
  flex-wrap: nowrap;
}
.arabic .dateMonth input {
  width: 61.8%;
}
.GernalForm
  span.MuiTypography-root.MuiTypography-div.flex-center1.css-37jdci-MuiTypography-root {
  max-width: 500px;
  width: 100%;
  margin-left: 10px;
  font-size: 12px;
}

.commentOuter.formCooment {
  padding: 20px 20px;
  display: inherit;
}

label.uploadFile {
  font-size: 12px;
  padding: 10px;
}

.MainRouting h2 {
  font-size: 20px;
  color: rgb(43 86 76);
  font-weight: 600;
}

.butoonValidateRequest {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tableHeading h3 {
  font-size: 16px;
  font-weight: 600;
  color: #2b564c;
  text-transform: capitalize;
}

.MainRouting h4 {
  font-size: 17px;
  color: #2b564c;
  font-weight: 600;
}

.FormDocPg .css-bhp9pd-MuiPaper-root-MuiCard-root {
  margin-bottom: 20px;
  /* min-height: 300px; */
  height: 12rem;
  display: grid;
}

.detailsBox h5 {
  color: #b7a160;
  font-size: 17px;
  font-weight: 500;
}
.TopsearchBar svg {
  margin-left: 10px !important;
  color: #2b564c;
}
.nav_wrapper_box svg {
  color: #2b564c;
}
.MainRouting h6 {
  font-size: 18px !important;
  font-weight: 600;
  color: #2b564c;
}

.MainRouting p {
  font-size: 12px;
  color: #484848;
}

.PartnerMeetPg h6 .MuiTypography-root.MuiTypography-subtitle1 .boardmetcontent {
  font-size: 12px !important;
  color: #484848 !important;
}

.clientInfoDocAccodian h4 {
  color: #fff;
  font-size: 12px;
}

button.iconText {
  color: #2b564c;
  font-size: 12px;
}

.OpenBtn {
  padding-right: 0px !important;
}

.TaskTab button {
  font-size: 14px !important;
}

.TaskTab {
  padding-top: 20px !important;
}
.authoritiesGroup span {
  font-size: 12px;
}
.flex-center svg.css-oidcdq-MuiSvgIcon-root {
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem !important;
  color: green !important;
  cursor: pointer;
}

button.OpenBtn {
  color: #2b564c;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;
}

button.OpenBtn span {
  margin-left: 0px;
}

.arabic .detailsBox svg {
  margin-left: 10px;
  margin-right: 0px;
}

.ModalFooter .css-11lq3yg-MuiGrid-root {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
}

.ModalHeading h4 {
  font-size: 16px;
  color: #2b564c;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
}

img.ProEditImg {
  height: 220px;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 50%;
  width: 220px;
}

.scroll-auto {
  max-height: 350px;
  overflow: auto;
  height: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.addMeetingPg .validate {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  justify-content: initial;
  display: flex;
}

.arabic .groupMeeting svg {
  margin-left: 10px;
}
.uploadFile.validate {
  justify-content: initial;
  display: flex;
}

.AddressBtn button {
  font-size: 10px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
  background: #b7a160;
}

.AddressBtn button svg {
  transform: rotate(90deg);
}

.addressMenu {
  position: relative;
}

.addressField .MuiPaper-elevation.MuiPaper-elevation1.MuiPaper-elevation8 {
  position: absolute;
  top: 148px !important;
  left: 373px !important;
  transform-origin: 0px 55px;
}

/* added new */
.mtt {
  margin-top: 10px;
}

.mtt button {
  margin-left: 10px;
  min-width: 75px !important;
}

.arabic .mtt button {
  margin-right: 10px !important;
  min-width: 75px !important;
}

.profile h6 {
  margin-right: 10px;
}

.formAddress {
  margin-bottom: 20px;
}

.timerBlock,
.logoutConfirm {
  display: flex;
  justify-content: center;
}

.logoutConfirm {
  margin-top: 30px;
}

.validationOtp {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.validationOtp button {
  margin-bottom: 20px;
  width: 300px;
  height: 50px;
}

.DateMeetDel p {
  padding: 10px 10px;
}

.timerBlock span {
  /* display: inline-block; */
  padding: 10px;
  margin-right: 30px;
  color: #b7a160;
  border: 1.5px solid #b7a160;
  border-radius: 5px;
}

.ant-picker {
  min-width: 415px;
  height: 37px;
}

.mr5 {
  margin-right: 40px !important;
}

.filterModel.css-11lq3yg-MuiGrid-root {
  margin-bottom: 20px;
}

/* end here */
/*table pagination start***/
.paginationDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.addMoreFiles svg {
  margin-right: 10px;
}

.AddaddressBTN {
  /* padding-right: 35px; */
  display: block;
  margin-top: 10px;
}

.MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
  padding-top: 10px;
}

.AddaddressBTN button {
  border: 1px solid #2b564c;
}

.paginationDiv li:hover:not(.active) {
  background-color: #2b564c;
  color: #fff;
}

.paginationDiv li {
  color: #fff;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.paginationDiv li.active {
  background-color: #4caf50;
  color: white;
}

.paginationDiv
  ul
  li
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: rgb(43 86 76);
  color: #fff;
}

.paginationDiv > .active > li {
  background-color: color;
}

.paginationDiv
  > .active
  > ul
  li
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: rgb(43 86 76);
  color: #fff;
}

input.custonInput[type='month']::-webkit-calendar-picker-indicator {
  /* display: none !important; */
  background-image: url('../images/arrow.svg');
  position: absolute;
  left: 25%;
  bottom: 10%;
}

::-webkit-scrollbar-track {
  background: #b4b4b4;
  border-radius: 5px;
}

.input {
  width: 100%;
}

.MainRouting
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
}

table a img,
table img {
  width: 18px;
  height: 18px;
}

/* .addMeetingPg .MuiAutocomplete-hasPopupIcon {
    height: 35.5px;
} */
.OpenBtn .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  padding: 6px 0px !important;
}

.workingHrsItems {
  display: flex;
  padding: 0 3em;
  border-right: 1px solid #b7a160;
  width: 25%;
  justify-content: center;
  /* align-items: center; */
  /* vertical-align: middle; */
}

.workingHrsItems:nth-child(4) {
  border-right: none;
}
.arabic .workingHrsItems:last-child {
  border-left: none !important;
}
.workingHorusGird {
  display: flex;
  justify-content: space-between;
}
.arabic .tableHeading h3 {
  white-space: nowrap;
}
span.iconLeft.iconHrs1 svg {
  width: 33px;
  height: 33px;
}
h3.timeHeadings {
  margin: 0px;
  padding: 0px;
  line-height: 0px;
  font-size: 16px;
}
.HideScroll .scroll-auto {
  /* max-height: inherit; */
  /* overflow: inherit; */
  height: auto;
}
.iconLeft {
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}

.hrsTimeWorks {
  width: 70%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.MainRouting {
  padding-top: 70px;
}

.MainRouting::-webkit-scrollbar-thumb {
  background: #2b564c;
  border-radius: 2px;
}

.mainInnerRouting svg:hover {
  background-color: transparent !important;
}

header .css-hu6rs9-MuiButtonBase-root-MuiIconButton-root:hover,
.Flagnav .css-zylse7-MuiButtonBase-root-MuiIconButton-root:hover,
header .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent;
}

header button:hover {
  background-color: transparent !important;
}

.MainRouting .css-9tj150-MuiButton-endIcon {
  display: inherit;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.flag-text {
  margin-left: 10px;
}
.m5 {
  margin-top: 6px;
}
.videoClass video {
  height: 450px;
  border: 1px solid #2b564c8a;
  border-radius: 20px;
}
.videoClass .innerVideo {
  display: grid;
  gap: 6px;
  text-align: center;
}

.dashTopGrid {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
  border-radius: 10px;
  padding: 10px 10px !important;
}

.documentContainer {
  height: 85vh;
}
.DocPage .documentContainer {
  height: auto;
}
.gridLeftbtn {
  display: flex;
  justify-content: end;
}

.logo {
  min-width: 500px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  background-repeat: no-repeat;
  width: 500;
  background-position: center;
  height: 100px;
  border-radius: 15px;
  background: url('../images/Shutterstock_1537207613\ 4096\ ×\ 2733\ 1.png');
  background-size: cover;
  background-position: center center;
  background-color: #f5f5f5;
}

.btnStyle {
  text-align: right;
}

.ListStyle span {
  width: 100%;
  max-width: 100%;
  padding: 20px 0px 20px 0px;
}

ul.ListStyle {
  width: 100%;
}

.dashListCust {
  width: 100%;
  max-width: 100%;
  padding: 0px;
}

.gridLeft span {
  text-align: left;
  display: flex;
  justify-content: start;
  color: #2b564c;
  font-size: 14px;
}

/**cordinator start*/
.cordinate-page .morningText {
  padding: 0 20px;
}

.arabic .ck-blurred.ck.ck-content.ck-editor__editable p {
  text-align: right;
}

/**cordinator end*/
/**table top style stART HERE*/
.multiColsBreak {
  display: flex;
  flex-wrap: wrap;
}

.multiColsBreak .MuiGrid-root {
  width: 100%;
  max-width: calc(33% - 20px);
  display: inline-block;
  margin: 20px 0 0;
}

.multiColsBreak .MuiGrid-root {
  margin-right: 20px;
}

.TableALLcommon .tableTop {
  width: 100%;
  max-width: calc(100% - 330px);
}

.TableALLcommon .tableTopbtn {
  width: 100%;
  max-width: 320px;
}

.TableALLcommon .css-1a0ielr-MuiGrid-root {
  display: flex;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
}

/*TABLE top end here**/
/*Tasks Pages Start**/

.clientInfoDocAccodian
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters
  p {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.btn_group {
  float: right;
}

input.notifyinput {
  width: 10%;
}

.notifyme {
  display: flex;
  gap: 10px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

button.Mui-selected.iconText {
  color: rgb(183, 161, 96);
}

.TabActive .css-1aquho2-MuiTabs-indicator {
  background: rgb(183, 161, 96);
}

/**Tasks pages end*/
/*flag style start here**/

.Flagnav {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.Flagnav li {
  display: inline-block;
  list-style: none;
}

.Flagnav.menuLanBtn span {
  /* margin-left: 10px; */
  color: #2b564c;
}

.Flagnav li:last-child {
  border-bottom: 0px;
}

.Flagnav .button-dropdown {
  position: relative;
}

.Flagnav li a span {
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
  color: #2b564c;
}

.ModalBG p {
  font-size: 14px;
  line-height: 20px;
  margin-left: -10px;
}
.arabic.ModalBG p {
  margin-right: 12px !important;
  padding-right: 12px !important;
}
.arabic.css-iv7vbz-MuiGrid-root {
  max-width: 20%;
}
.Flagnav li a:hover span,
.Flagnav li a.dropdown-toggle.active span {
  color: #2b564c;
}
.arabic .ModalBG .outlineBtn {
  margin-right: 10px !important;
}

.Flagnav .dropdown-menu {
  position: absolute;
  left: -31px;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  display: inline;
  top: 39px;
  background: #fff;
  padding: 6px;
  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.handleProfile {
  top: 60px !important;
}

.Flagnav .dropdown-menu a {
  width: auto;
  color: #2b564c !important;
}

.dropdown-menu .rootDiv {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu img {
  margin-top: 4px;
  width: 25px;
  height: 15px;
}

header .Flagnav a.dropdown-toggle {
  display: flex !important;
  align-items: center;
}

.MainRouting .iconText {
  font-size: 14px !important;
  color: #2b564c;
  text-transform: capitalize;
  font-weight: 500;
}

.Flagnav ul.dropdown-menu li a {
  text-transform: capitalize;
}

.Flagnav ul.dropdown ul li:first-child {
  border-top: solid 2px #bbb;
  padding-top: 20px;
}

/**flag style end here**/
/*add employee*/
.arabic button.addMoreFiles svg {
  margin-left: 10px;
}

.authoritiesGroup {
  width: 100%;
}

.arabic .AddaddressBTN {
  padding-right: 0px;
}

.arabic button.addMoreFiles.attachBtn {
  margin-right: 10px;
}

.arabic .GernalForm svg {
  margin-left: 10px;
}
.arabic .TabmobileBtn {
  margin-right: 10px;
}
.arabic .uploadFile p {
  padding-left: 8px;
}
/**langauge translator start here*/
.LanguageTranslate {
  display: flex;
  align-items: center;
  position: relative;
}

.LanguageTranslate span ul {
  position: absolute;
  padding-left: 10px !important;
}

.LanguageTranslate ul li {
  list-style: none;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

/**langauge translator end here*/
/* 
.ModalBG .buttonFill {
  margin-left: 10px;
  margin-right: 10px;
} */

.DocmntPg .iconText {
  font-size: 16px !important;
}

.Departments-Card {
  margin-top: 10px !important;
}

.clientInfoDocAccodian svg {
  color: #fff;
  margin-left: 10px;
}

.mainInnerRouting div[class^='MuiPaper-root'] ul li {
  padding-right: 0px;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic.css-ipujll-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.resonsivenone {
  display: none;
}

.logoFormpage img {
  /* position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%); */
  margin: 0 auto;
  background-repeat: no-repeat;
  height: auto;
  border-radius: 15px;
  background-position: center center;
  width: 40% !important;
  background-color: rgba(255, 255, 255, 0.54);
  padding: 15px 15px;
  border-radius: 16px;
  width: 460px !important;
}

.main {
  padding-top: 80px;
}

.content {
  padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.FormDocPg .css-3ogxn6-MuiButtonBase-root-MuiIconButton-root:hover,
.DeptContent .css-1ez7w0p-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent !important;
}

.FormDocPg .css-1u0k5eo-MuiCardContent-root {
  padding: 0px 10px;
}

.FormDocPg .css-1rwjz6-MuiCardActions-root {
  padding: 0px;
}

header {
  position: fixed !important;
  position: relative;
  top: 0 !important;
  width: 100% !important;
  box-shadow: 10px 10px;
  z-index: 9;
  left: 0px;
}

header a {
  display: inline-block;
  text-align: center !important;
  padding: 5px 10px !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.searchBar {
  position: absolute;
  width: 36.41px;
  height: 27.21px;
  left: 318.59px;
  top: 48.79px;
}

.chatIcon {
  width: 20px;
  height: 20px;
}

.MuiIconButton-sizeLarge {
  margin-right: 5px !important;
}

.main_nav span h6 {
  color: #2b564c;
}

.paragraphList {
  margin-top: 11px !important;
  padding: 20px !important;
}

/* Grid and button */
.Mright {
  margin-right: 10px !important;
}

.buttonBlank {
  color: #2b564c !important;
  border: 1px solid #2b564c !important;
  font-style: normal;
  font-size: 10px !important;
  height: 40px;
  min-width: 150px !important;
  text-align: center;
  width: auto;
}

.buttonFill {
  /* width: auto; */
  font-size: 10px !important;
  height: 40px;
  min-width: 150px !important;
  text-align: center;
  background-color: #2b564c !important;
  color: #fff !important;
}

.NoRightpadding {
  padding-right: 0px;
}

.gridRight {
  text-align: right !important;
  padding-right: 0px !important;
}

.FolderIcon img {
  margin-right: 10px;
}

.gridLeft {
  padding-right: 0px !important;
}

.css-hhvo2v-MuiListItem-root {
  padding-left: 0px !important;
}

.morningTextDate {
  /* text-align: right; */
  padding: 10px 10px !important;
  font-size: 12px;
  color: #2b564c;
}

.Action0con .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: #2b564c3b;
}

.Action0con .css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.Action0con .icon {
  width: 15px;
  height: 16px;
  fill: #2b564c;
}
.arabic .Action0con .icon {
  margin-left: 10px !important;
}
.arabic .Action0con span {
  margin-right: 10px !important;
}

.Action0con span {
  font-size: 14px;
  color: #484848;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #2b564c !important;
}

.lable {
  padding-top: 10px;
  font-style: normal;
  font-size: 20px;
  line-height: 34px;
}

.ModalBG
  .css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border-bottom: none !important;
}

.uploadFile {
  border: 1px solid #2b564c !important;
  color: #2b564c !important;
}

input {
  width: 95.8%;
  height: 15.5px;
  padding: 10px;
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
}

label {
  flex: 0 0 15%;
}

.mainContainer {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.25);
  border-radius: 12px;
}

.searchResponsive input {
  height: 26px;
}

.selectBox :focus {
  border: none !important;
}

.MainRouting .rbc-calendar {
  margin-bottom: 20px;
}

ul.menuTableList1 {
  padding-left: 0px;
}

.FolderN h6 {
  color: #484848 !important;
}

.arabic .FolderIcon img {
  margin-left: 10px;
  margin-right: 0px;
}

/**client ifo page start*/
.jobSection h6 {
  margin: 20px 0px;
  text-transform: uppercase;
}

.newStatus {
  justify-content: unset;
}

.profile {
  padding-top: 24px;
  display: flex;
}

.arabic header ul.menuTableList li a {
  display: flex;
  vertical-align: middle;
  width: 100%;
  justify-content: flex-start;
}
.arabic .TableMenu {
  right: auto;
  left: 29px;
}

.newText {
  margin-left: 10px !important;
  font-size: 15px !important;
}

.pro {
  font-weight: bold !important;
}

.profileIMG img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid #fff;
}

.profileIMG {
  padding: 2px;
  border: 1px solid #2b564c;
}

.newBtn1 {
  color: white !important;
  background-color: #2b564c;
  width: unset !important;
  text-align: right !important;
  display: flex !important;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  align-items: center;
  justify-content: center !important;
  padding: 12px 20px 10px 20px !important;
  cursor: pointer;
}

.fileContainer {
  position: relative;
}

.fileContainer img {
  margin-right: 40px;
}

.fileContainer label {
  flex: unset !important;
}

.css-1m4ljmh-MuiTypography-root {
  display: flex;
  align-items: center;
}

/* .css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
} */

.clientInfoDocAccodian.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background-color: #2b564c !important;
  color: white !important;
  border-radius: 3px !important;
  margin-bottom: 30px !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #2b564c !important;
}

.GreyBackground.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: rgba(239, 239, 239, 0.35) !important;
  color: #484848 !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: rgba(239, 239, 239, 0.35) !important;
}
.clientInfoDocAccodian.MuiAccordionSummary-expandIconWrapper svg {
  color: white !important;
}
.GreyBackground.MuiAccordionSummary-expandIconWrapper svg {
  color: #484848 !important;
  font-size: 30px !important;
}

.css-1pnmrwp-MuiTypography-root {
  width: 33%;
}

.folderSection {
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
}

.editI {
  color: #484848;
  padding-left: 10px;
}

.FormDocPg span {
  font-size: 16px;
  color: #2b564c;
  font-weight: 500;
}

.folderSection1 {
  display: flex;
}

.folderSection1 h6 {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: uppercase !important;
  color: #484848 !important;
  word-break: break-all;
}

.Partner-meet {
  position: relative;
}

.Partner-meet::before {
  content: '';
  display: block;
  top: 9%;
  position: absolute;
  width: 3px;
  height: 165px;
  background: #ff9900;
  border-radius: 20px;
}

.boardmetcontent h6 {
  font-size: 12px !important;
  text-transform: capitalize !important;
  color: #484848 !important;
}

/* .folderSection p {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-transform: capitalize;
    color: #484848;
} */
.PartnerMeetPg h4 {
  margin-bottom: 20px !important;
}

.new {
  background-color: unset;
  color: #2b564c !important;
  border: 1px solid #2b564c !important;
}

/*client info page end**/
/**action icon style*/
.Arrowicon {
  font-size: 12px !important;
  margin-left: 14px;
}

.userImg {
  border: 3px solid white;
  height: 50px !important;
  margin-right: 15px !important;
  width: 50px !important;
  overflow: hidden !important;
}

/**Dashboard content Latest Decisions page1**/
.main_nav li {
  color: #2b564c;
  font-size: 12px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.main_nav h6 {
  font-size: 14px;
  margin: 0;
  color: #2b564c;
}

.main_nav li:hover {
  background: #2b564c;
  color: #fff !important;
}

.side_nav_btn {
  font-size: 12px;
  border-radius: 10px !important;
  background: #2b564c !important;
  color: #fff !important;
}

.side_nav_btn h6,
.side_nav_btn svg path {
  font-size: 14px !important;
  color: #fff !important;
  fill: #fff !important;
}

.sidebar-Icons svg:hover path {
  fill: #fff;
}

.sidebar-Icons svg path {
  fill: #2b564c;
}

.sidebar-Icons svg {
  height: 20px;
  width: 20px;
}

.main_nav ul li.active {
  background-color: #000;
}

.main_nav li:hover .sidebar-Icons svg path,
.main_nav li:hover h6 {
  color: #fff;
  fill: #fff;
}

.FormDoc-Card {
  margin-top: 10px;
}

/*  menu css notifaction */
.notifactionBox .MuiPaper-root .MuiPaper-elevation {
  background-color: rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
}

.notifactionBox .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  background-color: #ececec !important;
  border-radius: 10px;
  border: 1px solid #ececec;
}

.notifactionTitle {
  font-size: 14px !important;
  color: black;
}

.divider {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.notifaction {
  font-size: 14px;
  color: #484848;
  line-height: 27px;
}

.viewAll {
  color: rgba(72, 72, 72, 1);
  font-size: 14px !important;
}

.listBoxNotify {
  display: flex !important;
  justify-content: space-between !important;
}

.listBoxItem {
  display: flex !important;
}

.time {
  color: rgba(72, 72, 72, 0.51);
  font-style: italic;
  padding-top: 5px;
  font-size: 10px !important;
}

.titleNotify {
  font-size: 14px !important;
}

.notifyButton {
  color: rgba(183, 161, 96, 1) !important;
  font-size: 12px !important;
}

.gridGroup {
  background-color: #fff;
  padding: 25px !important;
}

/*Accordion pages style start here**/
.chip_box {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.MeetingDetailsPage
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
  /* background: #F6F9F8; */
  margin-bottom: 30px;
  padding: 20px 10px !important;
}

/*Accordion pages style end here**/

/*modal start**/
#demo-simple-select {
  padding: 7px !important;
  width: 100% !important;
  font-size: 12px;
  color: #484848;
  height: 17.5px !important;
}

#demo-simple-select input {
  width: 100%;
}

.arabic span svg {
  margin-left: 10px;
}

.Mainmodal .MuiBox-root.css-z2v1mu {
  border-radius: 10px;
  border: none;
  padding: 20px 20px;
}

.ModalBG h6 {
  padding-bottom: 10px;
  font-size: 16px;
  color: #2b564c;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
}

/*modal end**/

/**dashboard Page css start**/

.dashboard.Btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #9999;
  padding: 20px 10px;
}

.dashboard.Btn:last-child {
  border-bottom: none;
}

.pending_req_box > .MuiGrid2-root,
.pending_req_box > .MuiGrid-root {
  /* margin: 0px 0 2px 0px; */
  border-bottom: 1px solid #9999;
  padding: 20px 10px;
}

.pending_req_box > .MuiGrid2-root:first-child,
.pending_req_box > .MuiGrid2-root:last-child,
.pending_req_box > .MuiGrid-root:first-child,
.pending_req_box > .MuiGrid-root:last-child {
  border: none;
}

.pending_req_box > .MuiGrid2-root:first-child {
  margin: 0;
}

.pending_req_box1 {
  padding: 20px 20px;
}

.DesText {
  margin: 15px 0px;
}

.Arabic .Mright {
  margin-left: 10px !important;
}

.Tasksplpage.MuiBox-root.css-0 {
  margin-top: 20px;
}

.Arabic .profileWrapper {
  padding: 0px;
}

.ProfileMenu {
  height: auto !important;
}

/*dashboard end**/
.main_nav {
  margin-top: 70px !important;
  /* padding-right: 10px !important; */
}

.mainLogin {
  background: url('../images/Shutterstock_1537207613\ 4096\ ×\ 2733\ 1.png');
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
}

.loginFlex input {
  border-radius: 4px !important;
  /* width: 90%; */
  height: 12.5px;
  border: 1px solid #d5dbe1;
}

.borderButton {
  margin-top: 40px;
  border-radius: 20px !important;
}

.filterModelAcc p:nth-child(1) {
  float: right;
}

.filterDate {
  justify-content: flex-start;
}

a {
  text-decoration: none !important;
}

.filterModelAcc p {
  text-align: left;
  /* margin-left: 10px; */
  width: 40%;
}

.filterModelAcc input {
  text-align: left;
  /* margin-left: 10px; */
  width: 100%;
}

.fileDelete svg {
  cursor: pointer;
  width: 10px;
  border-radius: 5px;
  padding: 6px;
  fill: red !important;
}

.fileDelete {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0px 10px;
}

.filterModelAcc {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.fileDelete span {
  font-size: 12px;
}

.filterDate {
  justify-content: flex-start;
}

/* //////////GOURAV//////////////////////// */
.TableALLcommon {
  background-color: #fff;
  border-radius: 10px;
}

/* /////////////////////////////////////////////////// */
.noficationPg p {
  margin-left: 10px;
}

.arabic .noficationPg p {
  margin-right: 10px;
}

.BodDecisionPage .BodMain {
  margin-left: 8px;
}

header .css-1iz4iln {
  padding: 0px 5px !important;
}

/***22-11-22*/
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-nszouu-MuiPaper-root
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9.css-1qpzhgb-MuiGrid-root {
  padding-left: 0px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-nszouu-MuiPaper-root
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-5.css-z5pyi4-MuiGrid-root {
  width: 100% !important;
  margin-right: 0px !important;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-nszouu-MuiPaper-root
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.css-1equabv-MuiGrid-root {
  padding-left: 62px !important;
}

.mainContainer
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
  padding: 0px 10px 0px 10px;
  margin-bottom: 20px;
}

.MainRouting
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root,
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-5.css-z5pyi4-MuiGrid-root {
  width: 100% !important;
  margin-right: 0px !important;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px !important;
}

.MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

/***gental page***/
.genralinfoContant label {
  flex: 0 0 15%;
}

.genralinfoContant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.genralinfoContant input {
  width: 85%;
  height: 30.5px;
  padding: 10px;
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
}

.genralinfoContant
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100% !important;
}

#demo-simple-select:focus {
  outline: #757575 !important;
  box-shadow: none;
  border: none;
}

.customLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
}

select:focus {
  outline: 0;
}

.ByTxt {
  margin-top: 0px;
}

#demo-simple-select select {
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

#demo-simple-select select:focus {
  outline: none !important;
}

.newBtn {
  color: #2b564c !important;
  width: unset !important;
  background-color: unset !important;
  text-align: right !important;
  display: flex !important;
  justify-content: flex-end !important;
  margin-left: auto !important;
  padding: 12px 20px 10px 20px !important;
  cursor: pointer;
}

/* .setDft {
    margin-right: 10px !important;
} */

.addMoreFiles {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #2b564c;
  background: none;
}

.attachBtn {
  position: absolute;
  right: 25px;
  top: 6px;
  font-weight: bold;
  border: 1px solid #2b564c;
  padding: 3px 15px;
  border-radius: 5px;
  cursor: pointer;
}

::-webkit-file-upload-button {
  display: none;
}

.file {
  position: relative;
  visibility: hidden;
}

/***Partner MOdule start here**/
.calenderBox span,
.cardContact span {
  font-size: 12px;
}

p.expenseProfitTophead {
  font-size: 14px !important;
  font-weight: 500;
  /* text-align: left; */
}

.ExpensesProfitPage .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  margin-bottom: 15px;
}

.arabic .MeetingFolderPg .css-1747sbc-MuiGrid-root {
  justify-content: flex-end;
}

.tabss-meeting .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff;
  background: #2b564c;
}

.tabs-meeting-click button {
  border-radius: 50px;
  margin-left: 10px;
  color: #fff;
  border: 1px solid #2b564c;
  font-size: 12px;
  text-transform: capitalize;
  padding: 0px 30px !important;
  height: 20px !important;
  padding: 0px;
  min-height: 32px !important;
  background-color: #2b564c;
  opacity: inherit;
}

.Tabs-border {
  border-radius: 50px !important;
  margin-left: 10px !important;
  color: #2b564c !important;
  border: 1px solid #2b564c !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 0px 30px !important;
  height: 20px !important;
  padding: 0px;
  min-height: 32px !important;
  opacity: inherit !important;
}

.tabss-meeting {
  position: relative;
}

.tabss-meeting .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 35px;
}

.totalCiount {
  position: absolute;
  bottom: 10px;
  background-color: red;
  border-radius: 50%;
}

.tabss-meeting h6 {
  font-size: 12px !important;
  margin-top: 7px;
}

.tabss-meeting
  button.active
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  border-bottom: 3px solid red;
}

.tabss-meeting .css-jpln7h-MuiTabs-scroller {
  position: inherit !important;
}

.tabss-meeting button.active {
  border-bottom: 3px solid red;
}

.tabss-meeting .css-1gsv261 {
  border-bottom: none;
  border-color: none;
}

.ckEditor.MeetingDecisionPg {
  width: auto;
}

.MeetingDecisionPg
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  border: none;
}

.reference_code_box,
.reference_code_box *::after,
.reference_code_box *::before {
  box-sizing: border-box;
}

.left-ReferCode h4 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;
}

.profiledeptRef h4 {
  margin-bottom: 20px;
}

.profileFormDoc-Card {
  display: flex;
  padding: 15px 15px;
  align-items: flex-start;
  margin-bottom: 15px;
}

.totalCiount {
  position: absolute;
  top: -13px;
  background-color: #b7a160;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
  left: 13%;
  z-index: 9;
  font-size: 12px;
}

.totalCiount1 {
  position: absolute;
  top: -13px;
  background-color: #b7a160;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
  left: 24%;
  z-index: 9;
  font-size: 12px;
}

.totalCiount2 {
  position: absolute;
  top: -13px;
  background-color: #b7a160;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
  left: 33.5%;
  z-index: 9;
  font-size: 12px;
}

.totalCiount3 {
  position: absolute;
  top: -13px;
  background-color: #b7a160;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
  left: 43.5%;
  z-index: 9;
  font-size: 12px;
}

span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  display: none;
}

.profiledeptcontent {
  display: flex;
  justify-content: space-between;
}

.profiledeptRef .Pendding-sugestbtn {
  font-size: 10px;
  color: #ff0101;
  border-radius: 50px;
  border: 1px solid#FF0101;
  text-align: center;
  font-weight: 500;
  padding: 5px 5px;
  text-transform: capitalize;
}

.profileFormDoc-Card .Pendding-sugest {
  width: 100%;
  /* max-width: calc(100% - 75px); */
}

.profileFormDoc-Card .Pro-traIcon {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 75px;
}

.TechDeptBtn {
  justify-content: flex-end;
  padding: 0px !important;
  align-items: flex-start;
}

.TechDeptBtn svg {
  margin-right: 0px;
}

.TechDeptBtn button {
  padding: 0px;
  margin-right: 0px;
  color: #2b564c;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;
}

.cardContentstyl {
  margin-bottom: 10px;
  padding: 10px 0px;
}

.profileFormDoc-Card .cardContentstyl p {
  line-height: 28px;
  font-size: 14px !important;
  font-weight: 400;
  color: #484848;
}

.profiledeptcontent span {
  font-size: 16px;
  color: #484848;
  font-weight: 500;
  min-height: 10px;
}

.pending_suggestions_reqPG .gridLeft span {
  line-height: 30px;
  padding-left: 15px;
}

.reference_codeYellow {
  left: -1px;
  top: -2px;
  position: absolute;
  width: 170px;
  background: #b7a160;
  border-radius: 0px 26px 26px 0px;
  padding: 10px 6px;
  font-size: 12px;
}

.reference_code_box {
  padding: 20px 20px;
  background: #fcfbf9;
  border: 1px solid #c1c1c1;
  box-shadow: 0px 4px 4px rgb(122 121 121 / 10%);
  position: relative;
  margin-top: 20px;
}

.reference_codeYellow span {
  color: #fff;
}

.LeftRightContentsec {
  display: flex;
  width: 100%;
  padding-top: 40px;
}

.left-ReferCode {
  width: 80%;
  padding-right: 5%;
}

.LeftRightmeetDecision {
  display: flex;
}

.rightMeetdec {
  width: 90%;
}

.left-ReferCode span {
  font-size: 14px;
  line-height: 22px;
  color: #2b564c;
}

.right-ReferCode {
  justify-content: flex-end;
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.leftMeetdec {
  width: 10%;
  padding-right: 10px;
}

.ReferDescription ul li {
  list-style: none;
  display: inline-block;
  padding: 20px 50px;
  padding-left: 0px !important;
  color: #b7a160;
  font-size: 14px;
}

.ReferDescription ul {
  margin-top: 10px;
  padding-left: 0px;
}

.right-ReferCode button {
  /* margin-bottom: 20px; */
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.Prophetic-Refertop .searchResponsiveLaw {
  position: relative;
  /* width: 100%; */
  max-width: 50%;
  /* margin-right: 0px !important; */
}
.Prophetic-Refertop .searchResponsiveLaw input {
  margin-right: 40px;
}
.Prophetic-Refertop {
  margin-bottom: 30px;
}

.delbtnRefrence {
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

.FormsuggestForm {
  width: 100%;
  display: flex;
  margin: 20px 0px;
}

.SuggestDetailPg .RefresugstBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}

.PublicDecisionsBtn button {
  font-size: 10px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 6px 10px;
  text-transform: capitalize;
  background: #b7a160;
  margin-left: 10px;
}

.PublicDecisionsBtn button:hover {
  border: 1px solid #b7a160;

  color: #b7a160;
}

.arabic .PublicDecisionsBtn {
  margin-right: 10px;
}

.AddEmpGenInfo1 {
  padding: 20px 20px;
}
.addMeetingPg .inputBox input {
  width: 97%;
  font-size: 12px;
  color: #484848;
}
.AddEmpGenInfo1 p {
  font-weight: 500;
  font-size: 14px;
  color: #2b564c;
}
.Twobtns {
  display: flex;
  width: 100%;
}

.Twobtns label {
  margin-left: 10px;
}

.customLabel2 p {
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px !important;
  line-height: 34px;
  color: #2b564c !important;
}

.GenDropdown1 p {
  padding: 0px 10px;
  background: rgba(169, 194, 187, 0.1);
  border-radius: 5px;
  font-size: 12px;
  line-height: 38px;
  color: #484848;
}

.DocPage .commentBox span {
  color: #2b564c;
  font-size: 12px;
}

.groupMeeting1 {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

.DocPage .commentBox span small {
  color: #b7a160;
  margin-left: 8px;
  font-weight: 500;
}

.guestMeeting1 {
  display: flex;
  width: 23%;
  justify-content: space-between;
}

.groupMeeting2 {
  display: flex;
  width: 30%;
  justify-content: center;
}

.AddEmpGenInfo1 p.text-w {
  width: 80%;
  line-height: 24px;
}

.groupMeeting2 p {
  margin-right: 20px;
}

.GenDropdown1 {
  display: flex;
}

.DocPage .commentBox {
  justify-content: start;
}

.DocPage .css-3jbzvu-MuiGrid-root {
  justify-content: flex-start;
}

.AddressBtn button:hover {
  border: 1px solid #b7a160;
  color: #fff;
  background: #b7a160;
}

.AddressBtn {
  margin-bottom: 20px;
  margin-left: -10px;
}

button#long-button svg:hover {
  border: none;
  background: none;
}

.filterModelAcc p {
  text-align: left;
  margin-left: 10px;
  width: 100%;
}

.arabic .filterModelAcc p {
  margin-left: 10px;
}

.arabic .TableALLcommonTopLaw .searchResponsiveLaw input {
  margin-right: 40px;
}

.profile-ImGsec {
  display: flex;
  justify-content: center;
}

.profile-ImGsec .css-1wlk0hk-MuiAvatar-root {
  width: 204px;
  height: 204px;
  border: 2px solid #ffffff;
}

.ProfileEditIocon {
  position: relative;
}

.ProfileEditIocon svg {
  position: absolute;
  bottom: 50px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 20px 12px rgb(204 204 204 / 25%);
  border-radius: 50px;
  padding: 10px 10px !important;
}

.ProfileEditIocon svg:hover {
  background: #ffffff;
  box-shadow: 0px 20px 12px rgb(204 204 204 / 25%);
  border-radius: 50px;
}

.proImg {
  display: flex;
  justify-content: center;
}

.ProifileSection {
  position: relative;
}

.ProfileditsEc {
  position: absolute;
  top: -40px;
  right: 12%;
  width: 100%;
  max-width: 190px;
  height: auto;
  z-index: 999999;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
  border-radius: 10px;
  bottom: 0%;
}

.gridLeftbtnP {
  display: flex;
  justify-content: center;
  margin-top: 10% !important;
}

.ProfileditsEc .menuTableListP li {
  border-bottom: 1px solid rgb(43 86 76 / 39%);
  padding: 15px 10px;
  text-align: left;
  vertical-align: middle;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
}

.arabic .ProfileEditIocon svg {
  position: absolute;
  bottom: 50px;
  right: -280px;
}

.ProfileditsEc.uploade_imagee ul label {
  width: 100%;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #2b564c;
  border-radius: 0;
  color: #2b564c;
}
.ProfileditsEc.uploade_imagee ul label:last-child {
  border-bottom: none;
}
.arabic .menuTableListP span {
  margin-right: 10px;
}

.ProfileditsEc .menuTableListP {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 0px 0;
  border-radius: 5px;
  border: 1px solid #2b564c;
  padding: 0 10px;
}

.ProfileditsEc .menuTableListP li a {
  vertical-align: middle;
  display: flex;
}

.dashTopProfilEdit {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(204 204 204 / 25%);
  border-radius: 10px;
  padding: 10px 10px !important;
  width: 1040px;
  min-height: 550px;
}

.HolidayBox {
  margin-top: 20px;
}

.holiBox {
  background: #ffffff;
  box-shadow: 1px 4px 4px rgb(51 51 51 / 12%), 0px 1.68px 4px rgb(0 0 0 / 8%);
  border-radius: 6.71px;
  min-height: 140px;
}

.holidatTitle h5 {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  background: #b7a160;
  border-radius: 6.71px 6.71px 0px 0px;
  color: #fff;
}

.holidaycontent {
  padding: 10px 10px;
}

.holidaycontent p {
  font-size: 12px;
  color: #484848;
}

.holidaycontent h6 {
  font-size: 12px !important;
  line-height: 36px;
  color: #484848;
}

.ProTaskInner {
  display: flex;
  justify-content: space-between;
}

.pro_taskdate button {
  justify-content: flex-start;
  display: flex;
}

.ProfiletaskBtn button {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  color: #484848;
}

.protaskDescription {
  width: 80%;
  padding-right: 5%;
}

.protaskDescription ul {
  padding-left: 0px;
  margin-top: 0px;
}

.protaskDescription ul li {
  list-style: none;
  display: inline-block;
  padding: 10px 20px;
  color: #484848;
  font-size: 14px;
}

.descriptionTasks {
  display: flex;
  justify-content: flex-start;
}

.descriptionTasks p {
  padding-left: 20px;
  margin: 0px;
}

.descriptionTasks span {
  padding: 10px 20px;
  color: #484848;
  font-size: 14px;
  width: 15%;
}

.descriptionTasks p {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
  line-height: 22px;
  color: #484848;
  width: 80%;
}

.right-taskBtn {
  justify-content: flex-end;
  width: 20%;
  display: flex;
  flex-wrap: wrap;
}

.right-taskBtn button {
  margin-bottom: 20px;
  margin-right: 0px !important;
}

li.LText1,
li.LText3 {
  width: 20%;
}

li.LText,
li.LText2 {
  width: 15%;
}

.ReqstAsignPage .dd {
  display: flex;
  justify-content: space-between;
}

/*hr module end**/
.FilterReassign {
  display: flex;
  align-items: flex-end !important;
}

.language_select_mobile {
  display: none;
}

.language_select_desktop {
  display: block;
}

.mainInnerRouting {
  background-color: '#F5F5F5 !important';
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.arabic .Mright {
  margin-left: 10px !important;
}

.mobile_togle_btnn {
  display: none;
}

.nav_wrapper_box .mobile_sec_icon {
  display: none;
}

/*@1920 start**/

@media only screen and (max-width: 1920) {
  /* .arabic .ModalBG .validate {
    left: 2% !important;
} */
  .arabic .Mright {
    margin-left: 10px !important;
  }

  .arabic .sidebar-Icons svg {
    margin-left: 15px;
  }

  .arabic .TableALLcommon .buttonBlank {
    margin-left: 0px;
    margin-right: 10px;
  }

  .arabic .btnContainer.btn.folderSection button {
    margin-left: 10px;
  }

  .arabic .buttonBlank {
    margin-left: 10px !important;
  }

  .Arabic .profileWrapper {
    padding: 0px;
  }

  .Arabic .ProfileDetail span,
  .Arabic .ProfileDetail p {
    padding-left: 1rem;
  }

  .Arabic .ProfileDetail {
    text-align: right;
    padding-left: 1rem;
  }

  .arabic .userImg {
    margin-right: 0px !important;
    position: absolute;
    right: 0%;
    bottom: 0%;
  }
}
.arabic .searchResponsive input {
  padding-right: calc(1em + 32px);
  background: none;
}

@media only screen and (max-width: 1600px) {
  .groupMeeting1 {
    width: 38%;
  }
  .groupMeeting p {
    display: flex;
    flex-wrap: nowrap;
  }
  .arabic .btnContainer.btn.folderSection button {
    margin-left: 10px;
  }
  .css-ahj2mt-MuiTypography-root {
    font-size: 11px !important;
  }
  .arabic .css-1iz4iln {
    left: auto;
    right: 0;
  }

  .arabic .main_nav h6 {
    margin-right: 15px;
  }

  .Arabic .Mright {
    margin-left: 10px !important;
  }
}
.arabic .reference_codeYellow {
  width: auto;
  right: -1px;
  top: -2px;
  position: absolute;
  width: 170px;
  background: #b7a160;
  border-radius: 26px 0px 0px 26px;
  padding: 10px 6px;
  font-size: 12px;
}

.arabic .right-ReferCode .buttonBlank.Mright {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
@media (max-width: 1440px) {
  input.custonInput[type='month']::-webkit-calendar-picker-indicator {
    background-image: url('../images/arrow.svg');
    position: absolute;
    left: 38%;
    top: 30%;
  }
  .sideBarShow .sickLeave,
  .casual_leave,
  .annual_leave {
    font-size: 10px !important;
  }
  .arabic .leftMeetdec {
    padding-left: 10px;
  }

  .arabic .left-ReferCode {
    padding-right: 0%;
  }

  .arabic .ReferDescription ul li {
    padding: 20px 0px;
  }

  .arabic .ReferDescription ul {
    padding-right: 0px;
  }

  .arabic .right-ReferCode .buttonBlank.Mright {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .arabic .reference_codeYellow {
    width: auto;
    right: -1px;
    top: -2px;
    position: absolute;
    width: 170px;
    background: #b7a160;
    border-radius: 26px 0px 0px 26px;
    padding: 10px 6px;
    font-size: 12px;
  }

  .arabic .left-ReferCode {
    padding-right: 0%;
  }

  .arabic .Mright {
    margin-left: 10px !important;
  }

  .arabic .profileWrapper {
    padding: 0px !important;
  }

  .arabic .main_nav h6 {
    margin-right: 15px;
  }

  .arabic .btnContainer.btn.folderSection button {
    margin-left: 10px;
  }

  .mainInnerRouting .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-bottom: 20px;
  }

  .sideBarShow .workingHrsItems {
    padding: 0 0.5em;
  }
}

@media screen and (max-width: 1024px) {
  input.custonInput[type='month']::-webkit-calendar-picker-indicator {
    background-image: url('../images/arrow.svg');
    position: absolute;
    left: 40%;
    top: 20px;
  }
  .sideBarShow.sickLeave,
  .casual_leave,
  .annual_leave {
    font-size: 10px !important;
  }
  .sideBarShow div.btn_groupLaw {
    flex-wrap: nowrap;
  }
  .notifyme
    p.MuiTypography-root.MuiTypography-body1.ByTxt.css-ahj2mt-MuiTypography-root {
    margin-top: 0px !important;
  }

  .AddEmpGenInfo p .ByTxt {
    margin-top: 0px !important;
  }

  .workingHrsItems {
    padding: 0 0.5em;
  }

  .Twobtns {
    display: block;
    width: 100%;
  }
  .addMeetingPg .Twobtns {
    display: flex;
    width: 60%;
  }
  .Twobtns .sideBarShow .FormsuggestForm label {
    width: 100%;
    display: flex;
    white-space: nowrap;
  }

  .GenDropdown1 p {
    margin-left: 10px;
  }

  .groupMeeting1 {
    width: auto;
  }

  .arabic .right-ReferCode {
    width: 20%;
  }

  .validate {
    margin-top: 39px;
  }

  .modalGroup .lable {
    padding-top: 0px;
  }
  .modalGroup.button {
    margin-right: 10px;
  }
  .arabic.ModalBG p {
    margin-right: 15px !important;
  }
  .modalGroup h1.lable {
    margin-bottom: 10px;
  }

  .MainModal .ModalBG {
    width: 100%;
    max-width: 70%;
  }

  .commentBox img {
    width: auto;
    height: 30px;
  }

  .sideBarShow .FormsuggestForm {
    justify-content: flex-start !important;
  }

  .sideBarShow .guestMeeting1 {
    width: 40%;
  }

  .sideBarShow .groupMeeting1 {
    width: 50%;
  }

  .sideBarShow .empField svg {
    margin-right: 0px;
  }

  .sideBarShow .TableALLcommon th.MuiTableCell-head.MuiTableCell-stickyHeader {
    white-space: nowrap;
  }

  .sideBarShow .PartnerMeetPg .btnContainer {
    padding-bottom: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .sideBarShow .PartnerMeetPg .btnContainer button {
    margin-bottom: 10px;
  }

  .FormsuggestForm {
    padding: 0px;
    max-width: 100%;
  }

  .sideBarShow .FormsuggestForm label {
    width: 30%;
    display: flex;
    white-space: nowrap;
  }

  .sideBarShow .FormsuggestForm input {
    width: 70%;
  }

  .FormsuggestForm label {
    font-size: 12px;
  }

  .ReferDescription ul {
    padding-right: 0px;
  }

  .ReferDescription ul li {
    padding: 20px 15px;
    font-size: 12px;
  }

  .Prophetic-Refertop .searchResponsiveLaw {
    max-width: 40%;
  }

  .sideBarShow .MainRouting .css-1d4hkr9 {
    margin-right: 0px;
  }

  .sideBarShow .TableALLcommon .tableTop {
    max-width: 100% !important;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    justify-content: space-between !important;
  }

  .sideBarShow .TableALLcommon .tableTopbtn {
    width: 100%;
    max-width: 100%;
  }

  .sideBarShow .btn_group.css-e53awj-MuiStack-root {
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
  }

  .sideBarShow .gridLeft span {
    margin-bottom: 10px;
  }

  .TableALLcommonTop .btn_group .Mright {
    margin-right: 0px !important;
  }

  .BodDecisionPage .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    margin-bottom: 15px !important;
  }

  .btnContainer .buttonBlank,
  .btnContainer .buttonFill {
    min-width: 120px !important;
  }

  .mainInnerRouting .css-138ouhw-MuiInputBase-root .MuiInputBase-input {
    width: auto;
  }

  .MainRouting .css-1d4hkr9 {
    margin-left: 0px !important;
  }

  .MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
    max-width: 100% !important;
    padding-top: 10px;
  }

  .arabic .userImg {
    margin-right: 0px !important;
    position: absolute;
    right: 0%;
    bottom: 0%;
  }

  .ProfileDetail {
    padding-left: 0 !important;
  }

  header .css-1iz4iln {
    padding: 0 0px !important;
  }

  .profileWrapper {
    padding: 0 7px 0 0 !important;
  }

  .profileFormDoc-Card .Pendding-sugest {
    max-width: calc(100% - 50px);
  }

  .profileFormDoc-Card .Pro-traIcon {
    max-width: 60px;
  }
}

.arabic .right-ReferCode {
  width: 20%;
}

/**991 -768*/
@media (max-width: 991px) and (min-width: 768px) {
  .sideBarShow .headtxt {
    width: 80%;
    white-space: inherit;
  }

  .sideBarShow .headerForm {
    padding: 10px 0px;
  }

  .mainInnerRouting {
    width: 100%;
  }

  .arabic .buttonGroup1 {
    justify-content: flex-start;
  }

  .mainContainer .css-e53awj-MuiStack-root {
    margin-bottom: 20px;
  }

  .mainContainer {
    margin-right: 0px;
  }

  .buttonBlank {
    border: 1px solid #2b564c !important;
    color: #2b564c !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    width: auto;
    font-size: 10px !important;
    line-height: 26px;
  }

  .buttonFill {
    font-size: 10px !important;
    height: 40px;
    font-weight: 400;
    text-align: center;
    background-color: #2b564c !important;
    color: #fff !important;
    width: auto;
    border: 1px solid #2b564c !important;
  }

  .gridRight {
    text-align: right !important;
    padding-right: 0px !important;
  }

  .gridLeft {
    text-align: right !important;
    padding-right: 0px !important;
  }

  .loginTitle {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #2b564c;
    text-transform: uppercase;
  }

  .lableLogin {
    font-weight: 400 !important;
    font-size: 15px !important;
    color: #484848;
  }

  .forgotCode {
    text-align: left;
    padding: 0 6px;
    margin-top: 6px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #2b564c;
  }

  .loginFlex .lableLogin {
    margin: 20px 0px 15px 0px !important;
  }

  .mainInnerRouting div[class^='MuiPaper-root'] ul li {
    padding-right: 0px;
  }

  .FormDoc-Card {
    margin-bottom: 15px;
  }

  .Departments-Card .DeptContent {
    margin-bottom: 10px;
  }

  .innerLogin {
    margin-top: 40px !important;
  }
}

.sideBarShow .empField svg {
  margin-right: 0px;
}

@media only screen and (max-width: 915px) {
  .profileWrapper {
    padding: 0 6px 0 0 !important;
  }

  .userImg {
    margin-right: 8px !important;
  }
}

/**@915 end**/
/**@958 start*/
@media screen and (max-width: 958px) {
  .sideBarShow .box .buttonGroup1 {
    justify-content: space-between;
    padding-left: 0px;
  }

  .mobile_togle_btnn {
    display: block;
    margin-left: 10px;
  }
}

@media screen and (max-width: 889px) {
  .header_nav {
    display: flex;
  }

  .ToggleSet {
    width: 170px;
  }

  .sideBarShow div.btn_groupLaw {
    flex-wrap: nowrap;
    display: flex;
    margin-top: 0px !important;
    justify-content: flex-end;
  }

  .sideBarHide .groupMeeting1 {
    width: 50%;
  }

  .sideBarHide .guestMeeting1 {
    width: 35%;
  }

  .sideBarHide .groupMeeting1 {
    width: auto;
  }

  .GenDropdown1 p {
    margin-left: 0px;
  }

  .sideBarHide .GenDropdown1 {
    flex-wrap: wrap;
  }

  .TableALLcommon .tableTopbtn {
    width: 100%;
    max-width: 100% !important;
  }

  .sideBarHide .AddEmpGenInfo1 {
    padding: 0px;
  }

  .btn_group {
    width: 100%;
    margin-top: 15px;
  }

  .TableALLcommon .css-1a0ielr-MuiGrid-root {
    display: flex;
  }

  .TableALLcommon .tableHeading {
    text-align: inherit;
  }

  .TableALLcommonTop {
    flex-wrap: wrap;
  }
}
.header_nav .nav_wrapper_box {
  position: relative;
}

.search_dropdown {
  position: absolute;
  height: 500px;
  background: #fafbfc;
  margin-top: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid black;
}
.center_loader {
  justify-content: center;
  display: flex;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .workingHrsItems {
    width: 25% !important;
  }

  .workingHrsItems {
    padding: 0 0.4em;
  }

  input.custonInput[type='month']::-webkit-calendar-picker-indicator {
    background-image: url('../images/arrow.svg');
    position: absolute;
    left: 22%;
    bottom: 10%;
  }

  .buttonGroup1 {
    margin-top: 8px;
  }

  .hideShowSearch.css-1iz4iln {
    display: block;
    right: 59px;
    top: 12px;
  }

  ul.main_nav {
    position: fixed;
    z-index: 5;
    height: 100%;
    min-height: 100%;
    overflow-y: scroll;
  }

  .main_nav.css-1vw6cjk-MuiList-root {
    width: 100%;
  }

  .allTaskPages .css-1jzi3sl-MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .DocPage .buttonGroup1 {
    justify-content: left;
  }

  .perviewBox.css-3jbzvu-MuiGrid-root {
    flex-wrap: nowrap;
    display: flex;
  }

  .multiColsBreak .MuiGrid-root {
    max-width: calc(50% - 20px);
  }

  .guestMeeting1 {
    width: 50% !important;
  }

  .OpenBtn {
    padding-right: 0px !important;
  }

  .sideBarHide .addMeetingPg .FormsuggestForm {
    justify-content: flex-start !important;
    display: flex;
  }

  .modalGroup h1.lable {
    margin-bottom: 10px;
    padding: 0px;
  }

  .latest_des_box .TableALLcommonTop {
    flex-direction: column;
  }

  .Departments-Card .DeptContent {
    margin-bottom: 10px;
  }

  .FormDoc-Card {
    margin-bottom: 15px;
  }

  .mainLogin {
    min-height: 100vh !important;
  }

  .forgotCode {
    margin-left: 20px !important;
  }

  .loginFlex input {
    width: 80% !important;
  }

  .loginFlex .lableLogin {
    margin: 20px 0px 15px 0px !important;
  }

  .innerLogin {
    margin-top: 30px !important;
  }

  .mainContainer .css-e53awj-MuiStack-root {
    margin-top: 15px;
  }

  .mainContainer .css-1d4hkr9 {
    margin-left: 0px;
  }

  .mainInnerRouting div[class^='MuiPaper-root']:nth-child(2) {
    padding: 15px !important;
    margin-right: 0px !important;
  }

  .mainInnerRouting div[class^='MuiPaper-root']:nth-child(2) .MuiPaper-root {
    margin-right: 0px !important;
  }

  .mainInnerRouting div[class^='MuiPaper-root']:nth-child(1) {
    margin-right: 0px !important;
  }

  .cordinate-page h6[class^='MuiTypography-root'] {
    padding-left: 0px;
  }

  .cordinate-page div[class^='MuiGrid-grid-xs-true'] {
    background: red;
  }

  .MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .mainContainer .css-1osj8n2-MuiGrid-root {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .mainContainer .css-e53awj-MuiStack-root {
    margin-bottom: 20px;
  }

  .mobile_togle_btnn {
    display: block;
  }

  .TopsearchBar svg {
    color: #2b564c;
  }

  .TopsearchBar.searchResponsive {
    background: none;
    border: none;
  }

  .header_nav .nav_wrapper_box .mobile_sec_icon {
    display: block;
    border: none;
    background: none;
    margin-top: 8px;
  }
  .arabic .header_nav .nav_wrapper_box .mobile_sec_icon {
    left: -70% !important;
    top: 18px;
  }
  .TableMenu.Profileclick .sideicon {
    margin-right: 10px;
  }
  .header_nav .nav_wrapper_box nav {
    display: none;
  }
  .arabic .TableMenu ul.dropdown-menu {
    left: 100%;
    bottom: 0%;
  }
  .header_nav .nav_wrapper_box {
    position: static;
  }
  .header_nav .nav_wrapper_box nav.hide {
    display: block;
    position: absolute;
    bottom: -42px;
    width: 95%;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    background: #eaeaea;
  }
  .search_dropdown {
    display: block;
    position: absolute;
    width: 95% !important;
    left: 50%;
    transform: translate(-50%);
    z-index: 9;
    top: 110px;
    margin-top: 0px;
  }
}

@media (max-width: 640px) {
  .TableALLcommon .css-y8ay40-MuiTableCell-root {
    padding: 0px !important;
  }
  .form_previewTemplate .headerForm {
    padding: 10px 0px;
  }
  .addMeetingPg .Twobtns {
    display: flex;
    width: 100%;
  }
  .addMeetingPg.authoritiesGroup.css-rswrts-MuiStack-root
    .css-1q60rmi-MuiAutocomplete-endAdornment {
    position: absolute;
    right: inherit;
    top: calc(50% - 14px);
    left: -6px;
  }
  .form_previewTemplate .headtxt {
    width: 100%;
    max-width: 70px;
    white-space: inherit;
  }
  .FullwidthBtn button {
    width: 100%;
    margin-bottom: 10px;
  }

  .TxtMbottom h4 {
    margin-bottom: 10px;
  }

  .CalenderWrapper .details_box {
    display: flex;
    flex-wrap: wrap;
  }

  .CalenderWrapper
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 24px !important;
    height: 40px !important;
  }

  .CalenderWrapper button {
    margin-top: 10px !important;
  }

  .ReqstAsignPage .dd {
    flex-wrap: wrap;
  }

  input.custonInput[type='month']::-webkit-calendar-picker-indicator {
    background-image: url('../images/arrow.svg');
    position: absolute;
    left: 40%;
    bottom: 10%;
  }

  .clientInfoDocAccodian.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
  }

  .MainRouting .iconText {
    word-break: break-all;
  }

  .allCommantAvtar .avatarcontainer {
    width: 295px !important;
  }

  .allCommantAvtar .avatarcontainer {
    display: inline-block !important;
  }

  .Tabmobile .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Tabs-border.css-cjctlb-MuiButtonBase-root-MuiTab-root {
    width: 95%;
    margin-bottom: 15px;
  }

  .TabmobileBtn button {
    width: 96.5%;
    margin-bottom: 15px;
  }

  .Flagnav .dropdown-menu {
    top: 37px;
    z-index: 99;
  }

  .tabss-meeting h6 {
    margin-bottom: 15px;
    text-align: center;
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
    display: none !important;
  }

  .mainInnerRouting div[class^='MuiPaper-root']:nth-child(2) {
    padding: 15px !important;
    margin-right: 0px !important;
  }

  .mainInnerRouting div[class^='MuiPaper-root']:nth-child(3) {
    padding: 15px !important;
    margin-right: 0px !important;
  }

  .clientInfoDocAccodian h4 {
    width: 100%;
  }

  .ModalContent .css-sm6xb8-MuiGrid-root {
    flex-basis: 100% !important;
    max-width: 99.5%;
  }

  .workingHorusGird {
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }

  .workingHrsItems {
    width: 100% !important;
    border-right: none;
  }

  .hrsTimeWorks {
    margin-top: 10px;
  }

  .dashboard.Btn {
    flex-direction: column;
    align-items: flex-start;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.buttonBlank.outlineBtn.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    width: 100% !important;
  }

  .FormsuggestForm .timeBox {
    width: 100% !important;
  }

  .interFile .folder {
    margin-left: 20px !important;
  }

  .timeBox1 {
    width: 100%;
  }

  .addMeetingPg
    .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

  .addMeetingPg .inputBox {
    width: 100% !important;
  }

  .noteremider {
    width: 72% !important;
  }

  .addMeetingPg .notifiyinput .notiftBox {
    width: 25%;
  }

  .notificationContainer .notiftBox {
    width: 100% !important;
  }

  .notificationContainer .noteremider {
    width: 100% !important;
  }

  .before-meet {
    width: 100%;
  }

  .ModalBG .buttonFill {
    margin-left: 0px;
    width: 100%;
  }

  .AddEmpGenInfo p {
    word-break: normal !important;
  }

  .AddEmpGenInfo p {
    word-break: unset;
  }

  .MainModal .ModalBG {
    width: 100%;
    min-width: 82% !important;
  }

  .notificationContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  .notificationContainer .notiftBox {
    width: 100%;
  }

  .notificationContainer .noteremider {
    width: 100%;
  }

  .before-meet {
    width: 100%;
  }

  .ModalFooter .css-11lq3yg-MuiGrid-root {
    gap: 10px;
  }

  .ModalFooter .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 100%;
  }

  .ModalBG .buttonFill .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 100%;
  }

  .notificationContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  .validate {
    margin-top: 39px;
  }

  .sideBarShow .TableALLcommon th.MuiTableCell-head.MuiTableCell-stickyHeader {
    white-space: nowrap;
    font-size: 10px;
  }

  .sideBarHide .groupMeeting2 {
    justify-content: flex-start;
    margin-top: 20px;
    width: auto;
  }

  .groupMeeting1 p {
    margin-right: 12px;
  }

  .groupMeeting1 {
    display: flex;
    justify-content: start;
  }

  .sideBarHide .guestMeeting1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .sideBarHide .guestMeeting1 {
    width: 60% !important;
  }

  .pending_suggestions_reqPG .gridLeftbtn {
    width: auto;
  }

  .MeetingDetailsPage .MainRouting p {
    margin-bottom: 10px;
  }

  .LeftRightContentsec {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .left-ReferCode {
    width: 100%;
    padding-right: 0%;
  }

  /* .right-ReferCode {
    justify-content: flex-start;
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  } */

  .Prophetic-Refertop .searchResponsiveLaw {
    max-width: 100%;
  }

  .pending_suggestions_reqPG .tableHeading h3 {
    margin-bottom: 10px;
  }

  .pending_suggestions_reqPG .tableTopLaw.TableLayout {
    flex-wrap: wrap;
  }

  .ReferDescription ul li {
    padding: 5px 0px;
  }

  #div-desktop {
    display: block;
  }

  .ExpensesProfitPage {
    padding-left: 10px;
  }

  .css-ucfjcj {
    position: absolute;

    min-width: 600px;
    border: none;
    min-width: auto;
  }

  .gridLeftbtn {
    margin-top: 10px;
  }

  .gridLeft span {
    margin-bottom: 10px;
  }
}

/*@640 end*/
/**@639 start*/
@media (max-width: 639px) {
  .empfileview {
    height: auto !important;
    font-size: 15px;
    flex-wrap: wrap;
  }

  button.addMoreFiles.attachBtn {
    margin-right: 0px;
  }

  .Text-Right {
    margin-left: -1% !important;
  }

  button.addMoreFiles.attachBtn {
    position: relative;
    right: 0px;
    top: 0px;
  }

  .flex-center1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
  }

  .attachment {
    width: 100%;
    display: flex;
  }

  .genralinfoContant {
    display: flex;
    margin-bottom: 15px;
  }
}

/**max 632 min 320 start**/
@media only screen and (max-width: 632px) and (min-width: 320px) {
  .forgotCode {
    margin-left: 0px !important;
  }

  .MainModal .ModalBG {
    width: 90%;
    min-width: 70% !important;
  }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
    align-items: center;
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeLarge.TestinSidebar.css-hu6rs9-MuiButtonBase-root-MuiIconButton-root {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .css-1d4hkr9 {
    margin-right: 0px !important;
  }

  .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
    color: black !important;
  }

  .latest_des_box .tableTop {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonBlank {
    border: 1px solid #2b564c !important;
    color: #2b564c !important;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 26px;
  }

  .buttonFill {
    border: 1px solid #2b564c !important;
    color: #ffffff !important;
    background-color: #2b564c !important;
    font-weight: 400;
    font-size: 10px !important;
  }

  .gridRight {
    text-align: right !important;
    padding-right: 0px !important;
  }

  .gridLeft {
    text-align: right !important;
    padding-right: 0px !important;
  }

  .TableALLcommonTop .searchResponsive {
    margin-top: 15px;
  }

  /* .morningTextDate span {
    margin-left: 0px !important;
    text-align: left !important;
    float: left;
  } */

  .mainContainer table {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .mainContainer .css-sml5u9-MuiPaper-root {
    margin-right: 0px !important;
  }

  /* .mainInnerRouting {
        padding-left: 0px !important;
    } */
  .searchResponsive .css-1d4hkr9 {
    margin-right: 0px !important;
  }

  .MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
    color: black !important;
  }

  .mainLogin {
    min-height: 100vh !important;
  }

  .innerLogin {
    width: 300px !important;
    margin-top: 30px !important;
  }

  .logoFormpage img {
    width: 270px !important;
  }

  .loginFlex input {
    width: 80% !important;
  }

  .mainContainer table {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .mainInnerRouting {
    background-color: #fff;
  }
}

.Departments-Card .DeptContent {
  margin-bottom: 10px;
}

.TaskTab button {
  font-size: 14px !important;
}

/*@320 start*/
@media (max-width: 320px) {
  .MainRouting .css-e53awj-MuiStack-root > :not(style) + :not(style) {
    margin: 0;
    margin-top: 15px;
  }

  .MainRouting .css-1osj8n2-MuiGrid-root {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .mainContainer .css-e53awj-MuiStack-root,
  .MainRouting .css-e53awj-MuiStack-root {
    flex-direction: column !important;
  }

  .MainRouting .css-1d4hkr9 {
    margin-right: 0px !important;
  }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
    align-items: center;
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeLarge.TestinSidebar.css-hu6rs9-MuiButtonBase-root-MuiIconButton-root {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .css-1d4hkr9 {
    margin-right: 0px !important;
  }

  .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
    color: black !important;
  }
}

/*@320 end*/

/*** correct style now*/

.profileWrapper {
  padding: 0 0 !important;
  border-radius: 60px !important;
  background-color: #2b564c !important;
  height: 55px;
  min-width: 210px;
}

.profileWrapper .userImg {
  margin: 0 !important;
}

.profileWrapper .MuiListItemText-root {
  padding: 0 15px !important;
}

.profileWrapper .MuiListItemText-root p {
  color: #fff !important;
  font-weight: 400;
  font-size: 12px !important;
}

.arabic .profileWrapper .MuiListItemText-root,
.arabic .ProfileDetail span {
  text-align: right;
}

.ProfileDetail span {
  font-size: 12px !important;
  font-weight: 800;
}

.ProfileModal {
  width: 200px;
  min-width: 200px;
}

/****table media start here**/
@media (max-width: 1250px) {
  .TableALLcommon .tableTop {
    width: 100%;
    max-width: 100%;
  }

  .TableALLcommon .tableHeading {
    text-align: inherit;
    width: 100%;
  }

  .TableALLcommon .btn_group {
    display: flex;
    justify-content: space-between;
  }

  .TableALLcommon .tableTopbtn {
    margin-top: 20px;
  }
}

/********************* CK Styles ********************/
.TableALLcommonTopLaw .tableTopLaw {
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 0;
}

.TableALLcommonTopLaw .tableTopbtnLaw {
  justify-content: flex-end;
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.TableALLcommonTopLaw .tableHeading,
.TableALLcommonTopLaw .searchResponsiveLaw {
  width: 100%;
  max-width: 100%;
}

.css-1d4hkr9 {
  margin-left: 0 !important;
}

.TableALLcommonTopLaw .searchResponsiveLaw > div {
  margin: 0 0 !important;
}

.TableALLcommonTopLaw .tableTopLaw {
  margin-bottom: 15px;
}

.TableALLcommonTopLaw .searchResponsiveLaw {
  position: relative;
  bottom: -25px;
}

div.btn_groupLaw {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-end;
}

html > body div.btn_groupLaw button {
  margin: 0 5px 10px;
}

html > body div.btn_groupLaw {
  /* margin: 0 -5px; */
  margin: 25px 0px;
}

@media (max-width: 768px) {
  .mainInnerRouting .css-138ouhw-MuiInputBase-root .MuiInputBase-input {
    width: 33%;
  }

  button.addMoreFiles.attachBtn {
    margin-right: 0px;
  }

  .addMeetingPg label.Titlezone {
    justify-content: flex-start !important;
  }

  .addMeetingPg label.Titlezone {
    justify-content: flex-start;
    display: flex;
  }

  .FormsuggestForm .timeBox {
    width: 100%;
  }

  .AddEmpGenInfo p {
    padding-left: 0px !important;
  }

  .sideBarHide .addMeetingPg .FormsuggestForm {
    display: flex;
    flex-wrap: wrap;
  }
  .otherinputform {
    display: flex;
    flex-direction: column;
  }
  .addMeetingPg .inputBox input {
    width: 100%;
  }

  .FormsuggestForm label {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .addMeetingPg .inputBox {
    width: 100%;
  }

  .MainModal .ModalBG.css-ucfjcj {
    width: 100%;
    min-width: 80%;
    max-width: 80%;
  }

  div.btn_groupLaw {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }

  .ReferDescription ul li {
    padding: 10px 10px;
  }
}

@media (max-width: 890px) {
  .language_select_mobile .Flagnav .dropdown-menu {
    top: -6px;
    z-index: 99;
    min-height: 90px;
  }

  .language_select_mobile.TableMenu ul.dropdown-menu {
    left: -75% !important;
  }

  .language_select_mobile {
    display: block;
  }
}
.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
  flex: 1;
}

@media (max-width: 767px) {
  .language_select_mobile .Flagnav .dropdown-menu {
    top: -6px;
    z-index: 99;
    min-height: 90px;
  }

  .language_select_mobile.TableMenu ul.dropdown-menu {
    left: -75% !important;
  }

  #div-desktop {
    display: block !important;
  }

  .language_select_mobile {
    display: block;
  }
  #div-desktop {
    display: block !important;
  }
  .language_select_desktop {
    display: none;
  }
  .TaskTab .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow-x: scroll !important;
  }

  .AddEmpGenInfo p {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
    line-height: 15px !important;
  }

  .MainRouting .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
    max-width: 100% !important;
    padding-top: 10px;
  }

  .TableALLcommonTopLaw .tableTopLaw,
  .TableALLcommonTopLaw .tableTopbtnLaw {
    width: 100%;
    max-width: 100%;
  }

  .TableALLcommonTopLaw {
    flex-wrap: wrap;
  }

  .TableALLcommonTopLaw .tableTopbtnLaw {
    justify-content: center;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .TableALLcommonTopLaw .tableTopbtnLaw {
    justify-content: left;
  }

  .arabic .TableALLcommonTopLaw .tableTopbtnLaw {
    justify-content: right;
  }
}

/**responsive end here **/
html body input::-webkit-input-placeholder,
input[type='value']:disabled {
  /* Chrome/Opera/Safari */

  color: #484848 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

html body input::-moz-placeholder,
input[type='value']:disabled {
  /* Firefox 19+ */
  color: #484848 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

html body input::placeholder,
input[type='value']:disabled {
  color: #484848 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

html body textarea::placeholder,
input[type='value']:disabled {
  color: #484848 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

.arabic button.OpenBtn span svg {
  margin-right: 10px;
}

.doucment {
  display: flex;
  justify-content: space-between;
}

.doucment .docClose svg:nth-child(2) {
  color: red;
  width: 10px !important;
  height: 10px !important;
}

.authoritiesGroup.multiselect {
  color: #484848;
  font-size: 12px;
  border: 1px solid #d5dbe1;
  border-radius: 5px;
  padding: 0 0 0 10px;
}

.chipstyle {
  margin-bottom: 10px;
}

.flex-center1 {
  display: flex;
  justify-content: flex-start;
  width: 10%;
}

.AutoComp .MuiChip-colorDefault {
  font-size: 11px;
  padding: 0;
  height: inherit;
}
.logoutHead {
  margin-left: 0 !important;
}
.logoutP {
  margin-left: 0 !important;
}
.attachment .flex-center {
  display: flex;
  justify-content: end;
  padding: 5px 5px;
}
.btnDisabled {
  background-color: #2b564c73 !important;
}
.center-flex {
  display: flex;
  justify-content: center;
}
.headerWidth {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.headerWidth > div {
  max-width: 100%;
}
