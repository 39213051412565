.editor {
  box-sizing: border-box;
  cursor: text;
  padding-inline: 15px;
  background: #fefefe;
  flex: 1;
  color: #484848;
  font-size: 12px;
  padding: 10px;
  border-radius: 6.71px;
  border: 1px solid #d5dbe1;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 20px;
}
